import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import FreightQuote from './page/FreightQuote'
import FreightList from './page/FreightList'
import Register from './page/Register'
import SignIn from './page/SignIn'
import FinishContract from './page/FinishContract'
import BuyerOrSeller from './page/BuyerOrSeller'
import './App.css'
import CreateContract from './page/CreateContract'
import EditContract from './page/EditContract'
import ContractDetails from './page/ContractDetails'
import Chat from './page/Chat';
import AboutUs from './page/AboutUs';
import ContactUs from './page/ContactUs';
import Checkout from './page/Checkout'
function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<FreightQuote  />} />
        <Route path="/FreightQuote" element={<FreightQuote />} />
        <Route path="/FreightList" element={<FreightList />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/FinishContract" element={<FinishContract />} />
        <Route path="/BuyerOrSeller" element={<BuyerOrSeller />} />
        <Route path="/CreateContract" element={<CreateContract />} />
        <Route path="/EditContract" element={<EditContract />} />
        <Route path="/ContractDetails" element={<ContractDetails />} />
        <Route path="/Chat" element={<Chat />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Checkout" element={<Checkout />} />
      </Routes>  
    </BrowserRouter>
    
  );
}

export default App;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from '../components/Header';
import HeroSlider from '../components/HeroSlider';
import SingleServices from '../components/SingleServices';
import Shipments from '../components/Shipments';
import Taram from '../components/Taram';
import RightSingleService from '../components/RightSingleService';
import LeftSingleServices from '../components/LeftSingleServices';
import ClintLogo from '../components/ClintLogo';
import NewsEvents from '../components/NewsEvents';
import Footer from '../components/Footer';
import Insights from '../components/Insights';
import LogisticsPlatform from '../components/LogisticsPlatform';
import '../App.css'
import Sitemap from '../components/Sitemap';
function AboutUs() {
  return (
    <>
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      <HeroSlider />
      <SingleServices />
      <Shipments />
      {/* <Taram /> */}
      <RightSingleService />
      {/* <LeftSingleServices /> */}
      <ClintLogo />
      {/* <NewsEvents /> */}
      {/* <Insights /> */}
      <Sitemap/>
      <div>
        <div className='bluepatti_oper'>
          <LogisticsPlatform />
        </div>
      </div>
      <Footer />
      </div>
    </>
  );
}

export default AboutUs;

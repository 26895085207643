
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, useNavigate } from 'react-router-dom';
import { getLoginInfo, signOut } from '../components/utils';
const Header = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        // Check if jwt cookie is present and not empty
        const userInfo = getLoginInfo();
        if (userInfo) {
          // If jwt cookie is present, read the uname cookie
          const uname = userInfo.uname;
          setUserName(uname || ''); // Set the username
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }
    }, []);

    const handleSignOut = () => {
        // Clear the cookies and log out
        signOut();
        setIsLoggedIn(false);
        navigate('/');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light_hader py-3 fixed-top">
            <div className="container">
                <a className="navbar-brand brand-name" href="/">
                    <div className='logo'>
                        <img src='/assets/images/logo4.png'></img>
                    </div>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-left" id="navbarNavDropdown" style={{paddingLeft:'50px'}}>
                    <ul className="navbar-nav">
                        <li className="nav-item nav-link_1 dropdown" style={{marginTop:'0px'}}>
                            <a className="nav-link" href="/FreightQuote" id="solutionDropdown" role="button" aria-expanded="false">
                            Request a Quote
                            </a>
                        </li>

                        {isLoggedIn && (
                        <React.Fragment>
                        <li className="nav-item nav-link_1 dropdown">
                            <a className="nav-link  dropdown-toggle" href="#solution" id="solutionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Contracts
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="solutionDropdown">
                                <li><a className="dropdown-item" href="/ContractDetails">My Contracts</a></li>
                                <li><a className="dropdown-item" href="/CreateContract">Create Contract</a></li>
                            </ul>
                        </li>

                        <li className="nav-item nav-link_1 dropdown">
                            <a className="nav-link dropdown-toggle" href="#solution" id="solutionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Chat
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="solutionDropdown">
                                <li><a className="dropdown-item" href="/Chat">My Chats</a></li>
                            </ul>
                        </li>
                        </React.Fragment>
                        )}

                        <li className="nav-item nav-link_1 dropdown" style={{marginTop:'-1px'}}>
                            <a className="nav-link dropdown-toggle" href="#solution" id="solutionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Company
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="solutionDropdown">
                                <li><a className="dropdown-item" href="/AboutUs">About Us</a></li>
                                <li><a className="dropdown-item" href="/ContactUs">Contacts Us</a></li>
                            </ul>
                        </li>



                    </ul>
                </div>
                <div className="d-none d-lg-block d-lg-block " style={{marginTop:'-8px'}}>
                    <div className='d-flex d-flex justify-content-between '>
                        <a className="nav-link search" href="#search"><i className="fas fa-search"></i></a>
                        {isLoggedIn ? (
                            <>
                                <p className="nav-link-name">{userName}</p>
                                <p className="nav-link-signout button_login mx-4" onClick={handleSignOut}>
                                <img src= "./logout.png" />
                                </p>
                            </>
                            ) : (
                            <>
                                <Link className="nav-link button_login mx-4" to="/SignIn">
                                Log In
                                </Link>
                                <Link className="nav-link button_signup" to="/Register">
                                Sign Up
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Header;


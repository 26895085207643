import Cookies from 'js-cookie';

export const domain = 'https://taram.io/';

export const writeCookie = (key, value) => {
    Cookies.set(key, value);
};

export const readCookie = (key) => {
    return Cookies.get(key);
};

export const getLoginInfo = () => {
    let jwt = Cookies.get('jwt');
    if(jwt) {
        return {'jwt': jwt, 'id': Cookies.get('id'), 'uname': Cookies.get('name'), 'email': Cookies.get('email'), 'mobile': Cookies.get('mobile'), 'pub_key': Cookies.get('pub_key'), 'role': Cookies.get('role')};
    } else {
        return false;
    }
};

export const signOut = () => {
    Cookies.remove('jwt');
    Cookies.remove('name');
    Cookies.remove('email');
    Cookies.remove('mobile');
    Cookies.remove('pub_key');
    Cookies.remove('role');
    Cookies.remove('id');
};


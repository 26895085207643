import React from 'react'
import { useNavigate } from 'react-router-dom';
function LogisticsPlatform() {
    const navigate = useNavigate();
    const handleNavigateAboutUs = () => {
        navigate('/AboutUs');
    };
    return (
        <div className="container" style={{maxWidth:'fit-content'}}>
            <div className="row lo_platform">
                <div className='col-lg-10 col-md-10 mx-auto text-center '>
                    <div className='lo_platform_textbox'>
                        <h1>Put Us to Work as Your B2B  <br/>
                        Cross-border Trade Platform</h1>
                        <p>
                            Schedule a call to learn how our platform delivers end-to-end results.
                        </p>
                        <button onClick={handleNavigateAboutUs} className="btn btn-light">About Us <i className="fas fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogisticsPlatform

import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import { domain, getLoginInfo, readCookie } from '../components/utils';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2';

function Contract() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState(false);
  const [id, setId] = useState(false);
  const [jwt, setJwt] = useState('');
  const navigate = useNavigate();
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [conditions, setConditions] = useState(['']);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['freight_cost']);
  const [isCheckedFreight, setCheckedFreight] = useState(false);
  const [isCheckedInspection, setCheckedInspection] = useState(false);
  const [isCheckedQuality, setCheckedQuality] = useState(false);
  const [isCheckedVendor, setCheckedVendor] = useState(false);
  const [isCheckedComprehensive, setCheckedComprehensive] = useState(false);
  const [isCheckedFinance, setCheckedFinance] = useState(false);
  const location = useLocation();
  const [formData, setFormData] = useState({
    cid: '',
    contractName: '',
    contractDesc: '',
    contractFrom: '',
    contractFor: '',
    contractMoney: 0,
    advanceMoney: 0,
    freightService: {},
    freightTotalCost: 0,
    loadingInspectionCost: 0,
    qualityAssuranceCost: 0,
    vendorAuditCost: 0,
    comprehensiveServiceCost: 0,
    financeServiceCost: 0,
    platformCost: 0,
    totalCost: 0,
    contractConditions: [],
  });
  

  useEffect(() => {
    // Update formData when the cookie changes
    const cid = location.state?.data?.cid;
    const userInfo = getLoginInfo();
    if (!userInfo) {
      navigate('/SignIn');
    } else {
      setEmail(userInfo.email);
      setJwt(userInfo.jwt);
      setId(userInfo.id);
      getContract(userInfo.jwt, cid);
    }
  }, []);

  useEffect(() => {
    // Update formData when the cookie changes
    const value =  parseInt(formData.contractMoney);
    let totalCost = parseInt(formData.contractMoney) || 0;
    if(isCheckedFreight) {
      totalCost = totalCost + formData.freightTotalCost;
    }
    if(isCheckedInspection) {
      totalCost = totalCost + 330;
    }
    if(isCheckedQuality) {
      totalCost = totalCost + Math.max(330, ((0.72 * value) / 100).toFixed(2));
    }
    if(isCheckedVendor) {
      totalCost = totalCost + 150;
    }
    if(isCheckedComprehensive) {
      totalCost = totalCost + Math.max(660, ((0.9 * value) / 100).toFixed(2));
    }
    setFormData((formData) => ({
      ...formData,
      freightTotalCost: cookies.freight_cost || 0,
      freightService: readCookie('freight_data') || {},
      totalCost: totalCost || 0,
    }));
  }, [cookies.freight_cost]);

  const getContract = (jwt, cid) => {
    setLoading(true);
    const headers = {
        'Authorization': `Bearer ${jwt}`,
    };
    fetch(`${domain}apis/contracts/view.php`, {
        method: 'POST',
        headers,
        body: JSON.stringify({"cid": cid}),
    })
    .then((response) => response.json())
    .then((data) => {
        setLoading(false);
        if(data.status == 'fail') {
            Swal.fire({
                title: 'Error!',
                text: data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
        } else {
          //console.log(data);
          const totalCost = parseInt(data.contract_cost) + parseInt(data.freight_total_cost) + parseInt(data.loading_inspection_cost) + parseInt(data.quality_assurance_cost) + parseInt(data.vendor_audit_cost) + parseInt(data.comprehensive_service_cost) + parseInt(data.platform_cost);

          setCheckedFreight(parseInt(data.freight_total_cost) > 0);
          setCheckedInspection(parseInt(data.loading_inspection_cost) > 0);
          setCheckedQuality(parseInt(data.quality_assurance_cost) > 0);
          setCheckedVendor(parseInt(data.vendor_audit_cost) > 0);
          setCheckedComprehensive(parseInt(data.comprehensive_service_cost) > 0);
          setCheckedFinance(parseInt(data.finance_cost) > 0);

          setFormData((formData) => ({
            ...formData,
            cid: data.cid,
            contractName: data.contract_name,
            contractDesc: data.description,
            contractFrom: data.contract_creator_mail,
            contractFor: data.contract_to_mail,
            contractMoney: parseInt(data.contract_cost),
            advanceMoney: parseInt(data.advance_cost),
            freightService: data.freight_service,
            freightTotalCost: parseInt(data.freight_total_cost),
            loadingInspectionCost: parseInt(data.loading_inspection_cost),
            qualityAssuranceCost: parseInt(data.quality_assurance_cost),
            vendorAuditCost: parseInt(data.vendor_audit_cost),
            comprehensiveServiceCost: parseInt(data.comprehensive_service_cost),
            financeServiceCost: parseInt(data.finance_cost),
            platformCost: parseInt(data.platform_cost),
            totalCost: totalCost,
            contractConditions: JSON.parse(data.conditions),
          }));
          setConditions(JSON.parse(data.conditions).map(condition => condition.desc));  
        }
    })
    .catch((error) => {
        setLoading(false);
        console.error('Error Creating Contract:', error);
    });
  }

  const addCondition = () => {
    setConditions([...conditions, '']); // Add a new empty textbox to the state
  };

  const handleChangeCondition = (index, value) => {
    const updatedConditions = [...conditions];
    updatedConditions[index] = value;
    setConditions(updatedConditions);
    let newCondition = {"desc": value, "status": "pending"};
    setFormData((formData) => {
      const updatedConditions = [...formData.contractConditions];
      updatedConditions[index] = newCondition;
      return {
        ...formData,
        contractConditions: updatedConditions,
      };
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    //setFormErrors({ ...formErrors, [id]: '' });
    if(id === 'contractMoney') {
      updateOtherCosts(value);
    }
  };

  const updateOtherCosts = (value) => {
    let totalCost = parseInt(value) || 0;
    if(isCheckedFreight) {
      totalCost = totalCost + formData.freightTotalCost;
    }
    if(isCheckedInspection) {
      totalCost = totalCost + 330;
    }
    if(isCheckedQuality) {
      totalCost = totalCost + Math.max(330, ((0.72 * value) / 100).toFixed(2));
    }
    if(isCheckedVendor) {
      totalCost = totalCost + 150;
    }
    if(isCheckedComprehensive) {
      totalCost = totalCost + Math.max(660, ((0.9 * value) / 100).toFixed(2));
    }
    totalCost = totalCost + Math.max(((2 * value) / 100).toFixed(2),0);
    totalCost = Math.max(totalCost.toFixed(2), 0);
    setFormData((formData) => ({
      ...formData,
      loadingInspectionCost: 330,
      contractMoney: Math.max(parseInt(value).toFixed(2), 0),
      qualityAssuranceCost: Math.max(330, ((0.72 * value) / 100).toFixed(2)),
      vendorAuditCost: 150,
      comprehensiveServiceCost: Math.max(660, ((0.9 * value) / 100).toFixed(2)),
      platformCost:  Math.max(((2 * value) / 100).toFixed(2),0),
      totalCost: totalCost
    }));
  }

  const handleValidation = () => {
    const errors = {};

    if (!formData.contractName.trim()) {
        errors.contractName = 'Contract name is required';
    }
    if (!formData.contractDesc.trim()) {
        errors.contractDesc = 'Contract description is required';
    }
    if (!formData.contractFor.trim() || !formData.contractFor.includes('@')) {
        errors.contractFor = 'Please enter a valid contract for email';
    }
    if (isNaN(formData.contractMoney) || parseInt(formData.contractMoney) <= 0) {
        errors.contractMoney = 'Please enter valid contract money and it must be greater than 0';
    }
    if (isNaN(formData.advanceMoney) || parseInt(formData.contractMoney) < parseInt(formData.advanceMoney)) {
      errors.contractMoney = 'Please enter valid advance money and it must be less than contract money';
  }
    if (isNaN(formData.financeServiceCost) || parseInt(formData.financeServiceCost) < 0) {
      errors.financeServiceCost = 'Please enter valid finance money';
  }
    if (conditions.length ===0 || !conditions[0].trim()) {
        errors.conditions = 'Atleast one condition to be given';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  const updateContract = () => {
    const validation = handleValidation();
    if(!validation) {
      window.scrollTo(0, 0);
    } else {
      setLoading(true);
      window.scrollTo(0, 0);
      const finalData = { ...formData };
      
      if(!isCheckedFreight) {
        finalData.freightTotalCost = 0;
        finalData.freightService = {};
      }
      if(!isCheckedInspection) {
        finalData.loadingInspectionCost = 0;
      }
      if(!isCheckedQuality) {
        finalData.qualityAssuranceCost = 0;
      }
      if(!isCheckedVendor) {
        finalData.vendorAuditCost = 0;
      }
      if(!isCheckedComprehensive) {
        finalData.comprehensiveServiceCost = 0;
      }
      if(!isCheckedFinance) {
        finalData.financeServiceCost = 0;
      }
      try {
      finalData.freightService = JSON.parse(finalData.freightService);
      }
      catch(err) {
      }
      const headers = {
        'Authorization': `Bearer ${jwt}`,
      };
      fetch(`${domain}apis/contracts/editContract.php`, {
          method: 'POST',
          headers,
          body: JSON.stringify(finalData),
      })
      .then((response) => response.json())
      .then((data) => {
          setLoading(false);
          if(data.status == 'fail') {
              Swal.fire({
                  title: 'Error!',
                  text: data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok',
              });
          } else {
              Swal.fire({
                title: 'Success!',
                text: data.message,
                icon: 'success',
                confirmButtonText: 'Ok',
              }).then(() => {
                navigate('/ContractDetails');
              });
          }
      })
      .catch((error) => {
          setLoading(false);
          console.error('Error Creating Contract:', error);
      });
    }
  };
  
  const openNewTab = () => {
    const relativePath = '/FreightQuote'; // Replace with your actual relative path
    const newTab = window.open(relativePath, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  const showDetails = (title, msg) => {
    Swal.fire({
      title: title,
      text: msg,
      icon: 'success',
      confirmButtonText: 'Ok',
    });
  };

  const setTotalCost = (event, extraCost) => {
    if (event.target.checked) {
      setFormData((formData) => ({
        ...formData,
        totalCost: Math.max((formData.totalCost + extraCost).toFixed(2), 0)
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        totalCost: Math.max((formData.totalCost - extraCost).toFixed(2), 0)
      }));
    }
  };

  const handleCheckboxChangeFreight = (event) => {
    setCheckedFreight(event.target.checked);
    setTotalCost(event, formData.freightTotalCost);
    if(event.target.checked) {
    }
  };

  const handleCheckboxChangeInspection = (event) => {
    setCheckedInspection(event.target.checked);
    setTotalCost(event, formData.loadingInspectionCost);
  };

  const handleCheckboxChangeQuality = (event) => {
    setCheckedQuality(event.target.checked);
    setTotalCost(event, formData.qualityAssuranceCost);
  };

  const handleCheckboxChangeVendor = (event) => {
    setCheckedVendor(event.target.checked);
    setTotalCost(event, formData.vendorAuditCost);
  };

  const handleCheckboxChangeComprehensive = (event) => {
    setCheckedComprehensive(event.target.checked);
    setTotalCost(event, formData.comprehensiveServiceCost);
  };

  const handleCheckboxChangeFinance = (event) => {
    setCheckedFinance(event.target.checked);
  };

  useEffect(() => {
    // Check if jwt cookie is present and not empty
    const userInfo = getLoginInfo();
    if (!userInfo) {
      navigate('/SignIn');
    } else {
      setEmail(userInfo.email);
      setJwt(userInfo.jwt);
      setFormData((formData) => ({
        ...formData,
        contractFrom: userInfo.email || ""
      }));
    }
}, []);

  return (
    <>
      <Header />
      <div className={`${loading ? 'loading-background' : 'body_f contract_bg py-5'}`}>
      {loading && <div className="loading-indicator"><img src = './loading.gif'></img></div>}
      <div className={`${loading ? 'loadingcontainer' : 'container'}`}>
          <div className='row bg-white bord_raduis_c mt-5 p-3'>
            <div className='col-lg-12 col-md-12 py-2'>
            {Object.keys(formErrors).length > 0 && (
                <div className="alert alert-danger mt-3">
                    {Object.values(formErrors).map((error, index) => (
                        <p key={index}>{error}</p>
                    ))}
                </div>
            )}
              <h1>Edit the smart contract</h1>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Contract Name </label>
                <input type="text" value={formData.contractName} id="contractName" className="form-control" placeholder="" onChange={handleChange} />
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Contract Description </label>
                <textarea id="contractDesc" value={formData.contractDesc} type="text" rows={'5'} className="form-control" placeholder="" onChange={handleChange} />
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Contract From </label>
                <input type="text" id="contractFrom" value={email}  disabled className="form-control" placeholder="" />
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Contract For </label>
                <input type="text" id="contractFor" value={formData.contractFor} disabled className="form-control" placeholder="" onChange={handleChange} />
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Contract Money in USD </label>
                <input type="text" id="contractMoney" value={formData.contractMoney} className="form-control" placeholder="" onChange={handleChange} />
              </div>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className="mb-3">
                <label className="form-label mb-3">Advance Money in USD </label>
                <input type="text" id="advanceMoney" value={formData.advanceMoney} className="form-control" placeholder="" onChange={handleChange} />
              </div>
            </div>


            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Freight Service </label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="freightService" checked={parseInt(formData.freightTotalCost) > 0} onChange={handleCheckboxChangeFreight} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" id="freightTotalCost" value={formData.freightTotalCost}  disabled className="form-control inpu_tbg  border-0" />
                  </div>
                  <div className="col-md-1">
                    <h5 className='sh_owde' onClick={openNewTab}> Edit Freight</h5>
                  </div>
                </div>

              </div>
            </div>


            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Loading Inspection </label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="loadingInspection" checked={parseInt(formData.loadingInspectionCost) > 0} onChange={handleCheckboxChangeInspection} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" id="loadingInspectionCost" value={formData.loadingInspectionCost} className="form-control inpu_tbg  border-0" disabled />
                  </div>
                  <div className="col-md-1">
                    <h5 className='sh_owde' onClick={() => showDetails('Loading Inspection', 'Loading Inspection Fee: $330 (fixed) per container.')}> Show details</h5>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Quality Assurance </label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="qualityAssurance" checked={parseInt(formData.qualityAssuranceCost) > 0} onChange={handleCheckboxChangeQuality} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" id="qualityAssuranceCost" disabled className="form-control inpu_tbg  border-0" value={formData.qualityAssuranceCost} />
                  </div>
                  <div className="col-md-1">
                    <h5 className='sh_owde' onClick={() => showDetails('Quality Assurance', 'Higher between 0.72% of the total consignment value and $330 per container per day.')}> Show details</h5>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Vendor Audit </label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1 col-2">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="vendorAudit" checked={parseInt(formData.vendorAuditCost) > 0} onChange={handleCheckboxChangeVendor} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" disabled id="vendorAuditCost" className="form-control inpu_tbg border-0 " value={formData.vendorAuditCost} />
                  </div>
                  <div className="col-md-1 col-3">
                    <h5 className='sh_owde' onClick={() => showDetails('Vendor Audit', 'Fixed Charge: $150 per man-day. Food for our surveyor/inspector to be provided by the seller vendor.')}> Show details</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Comprehensive Service </label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1 col-2">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="comprehensiveService" checked={parseInt(formData.comprehensiveServiceCost) > 0} onChange={handleCheckboxChangeComprehensive} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" disabled id="comprehensiveServiceCost" className="form-control inpu_tbg border-0 " value={formData.comprehensiveServiceCost} />
                  </div>
                  <div className="col-md-1 col-3">
                    <h5 className='sh_owde' onClick={() => showDetails('Comprehensive Service', 'Higher between 0.9% of the shipment value and $660.')}> Show details</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <label className="form-label mt-4 mb-3">Finance Service</label>
            </div>
            <div className='col-lg-12 col-md-12'>
              <div className='justify-content-between'>
                <div className='toggle_icon_c'>
                  <div className="col-md-1 col-2">
                    <div className="form-check form-switch  form-check_c mt-1">
                      <input className="form-check-input form-check-input_c " type="checkbox" id="financeService" checked={parseInt(formData.financeServiceCost) > 0} onChange={handleCheckboxChangeFinance} />
                    </div>
                  </div>

                  <div className="col-md-10">
                    <input type="text" id="financeServiceCost" value={formData.financeServiceCost} className="form-control inpu_tbg border-0 " onChange={handleChange} />
                  </div>
                  <div className="col-md-1 col-3">
                    <h5 className='sh_owde' onClick={() => showDetails('Finance Service', 'Mention how much finance you need for the consignment. Value must be less than or equal to total shipment cost with freight and other services.')}> Show details</h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <hr />
            </div>
            <div className="col-lg-12">
              <div className="d-flex mt-5">
                <h6 className='cos_tss'>Platform Cost</h6>
                <button className=' btn btn-primary ms-5'>{formData.platformCost}</button>
              </div>
            </div>


            <div className="col-lg-12 mt-3">
              <hr />
            </div>
            <div className="col-lg-12">
              <div className="d-flex mt-5">
                <h6 className='cos_tss'>Total Costs</h6>
                <button className=' btn btn-primary ms-5'>USD {formData.totalCost}</button>
              </div>
            </div>

            <div className="col-lg-12 mt-3">
              <hr />
            </div>

            <div className="col-lg-12">
            <h6 className="form-label mt-4 mb-3">Contract Conditions </h6>
            {conditions.map((condition, index) => (
              <div key={index} className="input-group mb-3">
                <input
                  type="text"
                  className="form-control conditions"
                  value={condition}
                  onChange={(e) => handleChangeCondition(index, e.target.value)}
                />
                {index === conditions.length - 1 && ( // Show + only on the last textbox
                  <span className="input-group-text plus-condition-icon" id="basic-addon2" onClick={addCondition}>
                    +
                  </span>
                )}
              </div>
            ))}
          </div>

            <div className="col-lg-12">
              <button type="button" className="btn btn-primary" onClick={updateContract}>
                Update Contract
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content p-4 pt-2">
              <div className="modal-header border-0">
                <h5 className="modal-title popover_title" id="exampleModalLabel">Shipment Information</h5>
                <button type="button" className="btn-close" onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-location-dot icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text ml-2 ">DEPARTURE <br /><span className="down_heading">Hamburg, DE</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-location-dot icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">ARRIVAL <br /><span className="down_heading">Tuticorin, IN</span>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6 ">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-calendar-days icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">READY TO LOAD <br /><span clclassNameass="down_heading">25 Nov,
                          2023</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-calendar-days icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">TYPE OF DELIVERY <br /><span className="down_heading">FCL</span>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="cargo_all">
                  <p className="cargo_heading mt-4 mb-0">CARGO DETAILS</p>
                  <div className="d-flex">
                    <h3 className="standard_20 me-3 mt-2">20 Standard</h3>

                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item">
                          <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">-</span>
                          </a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item">
                          <a className="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">+</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-9 col-lg-9">
                    <label for="Weight per ">Weight per container</label><br />
                    <input type="text"  className="w-100 mt-2 form-control" />
                  </div>
                  <div className="col-md-2 col-lg-2">
                    <div className="dropdown top_mt">
                      <button className="btn btn-secondary dropdown-toggle mt-4 kg_button" type="button"
                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        kg
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" href="#">10kg</a></li>
                        <li><a className="dropdown-item" href="#">30kg</a></li>
                        <li><a className="dropdown-item" href="#">50kg</a></li>
                      </ul>
                    </div>
                  </div>
                </div>


                <div className="Ocean_bgcol mt-4 d-flex justify-content-between ">
                  <div className="Ocean_Freight_heading">
                    Ocean Freight
                  </div>
                  <div>
                    <pre className="USD_253">USD  253</pre>
                  </div>
                </div>
                <div><hr /></div>

                <div className="d-flex costs_group_2 d-flex justify-content-between ">
                  <div>
                    <p className="mt-4 total_costs mb-4">Total Costs</p>
                  </div>
                  <div>
                    <button type="button" className="btn btn-primary mt-4 ms-4 mb-4">USD 2000</button>
                  </div>
                </div>

                <div className="Services_group">
                  <div className="Services_h mt-4 mb-3">
                    Services
                  </div>

                  <div className="d-flex justify-content-between ">
                    <div className="ship_text">
                      <p><img src="assets/images/monry.png" alt="" /> Freight Service</p>
                    </div>
                    <div className="turnoff_btn">
                      <div className="form-check form-switch">
                        <input className="form-check-input mt-2 contract_input" type="checkbox"
                          role="switch" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between ">
                    <div className="ship_text">
                      <p><img src="assets/images/monry3.png" alt="" /> Quality inspection service</p>
                    </div>
                    <div className="turnoff_btn">
                      <div className="form-check form-switch">
                        <input className="form-check-input mt-2 contract_input" type="checkbox"
                          role="switch" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between ">
                    <div className="ship_text">
                      <p><img src="assets/images/monry2.png" alt="" /> Quantity and Loading inspection Service </p>
                    </div>
                    <div className="turnoff_btn">
                      <div className="form-check form-switch">
                        <input className="form-check-input mt-2 contract_input" type="checkbox"
                          role="switch" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <div className="ship_text">
                      <p><img src="assets/images/monry1.png" alt="" />  Finance Service </p>
                    </div>
                    <div className="turnoff_btn">
                      <div className="form-check form-switch">
                        <input className="form-check-input mt-2 contract_input" type="checkbox"
                          role="switch" id="flexSwitchCheckDefault" />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  )
}

export default Contract

// Shipments.js

import React from 'react';

function Shipments() {
    return (
        <div className="sh-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mb-4 text-center py-4">
                        <h1>
                             A Single System For All <span> Your Cross-Border Needs</span>
                        </h1>
                    </div>
                </div>
                <div className="row">

                    <div className="col-md-4 mb-4">
                        <div className="card" style={{height:'280px'}}>
                            <div className="">
                                <div className="d-flex">
                                    <div className="icon_box">
                                        <div className="icon">
                                            <img src='assets/images/icon1.svg' />
                                        </div></div>
                                    <div className="heading">Simplify Workflows</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="card-text">Step out of the messy reality of searching for various vendors inclusive of freight providers, quality assurance providers and credit line providers.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="card" style={{height:'280px'}}>
                            <div className="">
                                <div className="d-flex">
                                    <div className="icon_box"><div className="icon"><img src='assets/images/icon2.svg' /></div></div>
                                    <div className="heading">Coordinate Confidence</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="card-text">Coordinate with all associated parties using the unified chat system, ensuring proper communication though out each transaction.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="card" style={{height:'280px'}}>
                            <div className="">
                                <div className="d-flex ">
                                    <div className="icon_box"><div className="icon"><img src='assets/images/icon3.svg' /></div></div>
                                    <div className="heading">Make Smarter Decisions</div>
                                </div>
                            </div>
                            <div className="card-body">
                                <p className="card-text">The aggregation of cross-border trade services by multiple vendors on Taram provides room for our customers to make smart decisions, and thereby choose the best services in the market.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Shipments;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { writeCookie, readCookie, getLoginInfo } from '../components/utils';
import TradeFinanceCard from '../components/Finance';
import QualityAssuranceCard from '../components/Quality';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

window.bookFreight = function(button, freightData) {
    writeCookie('freight_data', JSON.stringify(freightData));
    writeCookie('freight_cost', freightData.freight_cost);
    writeCookie('freight_name', freightData.freight_name);
    if(!getLoginInfo()) {
        window.location.href = '/SignIn';
    }
    var btns = document.getElementsByClassName('button_booking');
    for(var i=0; i<btns.length; i++) {
        btns[i].innerText = 'Add Freight';
    }
    if(button.innerText == 'Add Freight') {
        button.innerText = 'Added';
    } else {
        button.innerText = 'Add Freight';
    }
    const tradeFinanceElement = document.getElementsByClassName('feature_box');

    if (tradeFinanceElement) {
        const featurebox = tradeFinanceElement[tradeFinanceElement.length-1];
        featurebox.scrollIntoView({ behavior: 'smooth' });
    }
};
const truncateString = (value, maxLength) => {
    if (value.length > maxLength) {
        return value.substring(0, maxLength) + '...';
      } else {
        return value;
      }
}
const freightDropDown = () => {
    var FreightList = document.getElementById('Freights');
    var icon = document.getElementById('freightDropIcon');
    if(FreightList.style.display == "none") {
        FreightList.style.display = "block";
        icon.innerHTML = "arrow_drop_down";
    } else {
        FreightList.style.display = "none";
        icon.innerHTML = "arrow_drop_up";
    }
    
};
function Feature({ dataForFeatureComponent }) {
    const navigate = useNavigate();
    const [containerSize, setContainerSize] = useState(readCookie('container_size')?readCookie('container_size'):'20’ Standard');
    let freightList = `<img class="loading-icon" src="${process.env.PUBLIC_URL}/loading.gif" alt="Loading" />`;
    if(dataForFeatureComponent) {
        if(dataForFeatureComponent.freights && dataForFeatureComponent.freights.length>0) {
            freightList = '';
            for(var i=0; i<dataForFeatureComponent.freights.length; i++) {
                var freight = dataForFeatureComponent.freights[i];
                const truncatedFromCity = truncateString(freight.from_city, 25);
                const truncatedToCity = truncateString(freight.to_city, 25);

                var freightData = {'from_city': freight.from_city.replace(/'/g, ""), 'to_city': freight.to_city.replace(/'/g, ""), 'shipping_date': dataForFeatureComponent.shipping_date, 'freight_cost': freight.cost, 'freight_name': freight.name, 'freight_days': freight.days};

                freightList = freightList + "<div class='feature_box p-4 pb-0 mb-2'><div class='row'><div class='col-lg-9 col-md-9' style='width:70%'><div class='row'><div class='col-lg-12 col-md-12'><div class='d-flex'><div class='image_box_f'><img class='freight-logo' src='"+freight.logo+"' /></div><div class='text_box_f freight-name'><h3>"+freight.name+"</h3></div></div><div class='d-flex justify-content-between center_text_f'><div><h3>"+truncatedFromCity+"</h3></div><div><div class='d-flex'><img src='assets/images/ship.svg' class='px-2'></img>"+freight.days+"</div></div><div><h3>"+truncatedToCity+"</h3></div></div><div class='border_f'></div><div class='d-flex justify-content-between center_text_f py-3'><div><div class='d-flex'>Services  <img src='assets/images/truck.svg' class='px-2'></img></div></div><div><div class='dropdown dropdown_f px-0' style='display:none'><a class='btn dropdown-toggle px-0'>View Details</a></div></div></div></div></div></div><div class='col-lg-3 col-md-3' style='width:30%'><div class='booking'><div class='d-flex'><img src='assets/images/calendar_f.svg' class='px-2'></img> <span><b>Valid : </b> "+freight.valid+"</span></div></div><div class='booking'><div class='d-flex'><img src='assets/images/ids.svg' class='px-2'></img> <span><b>ID: </b>"+freight.shipment_id+"</span></div></div><div class='booking'><div class='d-flex'><img src='assets/images/leef.png' class='px-2'></img> <span> "+freight.co2+" kg CO2</span></div></div><div class='booking_butoon'><div class='d-flex justify-content-between'><h2>$ "+freight.cost+"</h2><a onclick='bookFreight(this, "+JSON.stringify(freightData)+");' class='button_booking' >Add Freight</a><div class='icon_box_f'><img src='assets/images/lblue.png' /></div></div></div></div></div></div>";
            }
        } else {
            freightList = `<b style="text-align: center;display: block;margin-top: 20px;width: 100% !important;"> No Freights found</b>`;
        }
    }
    const checkout = () => {
        navigate('/Checkout');
    };

    return (
        <div className='container'>
           
            <div className='row mb-5'>
                <div className='col-lg-3 col-md-3 feature_fliter_box p-4' style={{width:20+'%'}}>
                    <h2>Types of Container</h2>
                    <div className='p-3'>
                        <div className="form-check form-check_f">
                            <input checked={containerSize === '20’ Standard'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">
                                20' Standard
                            </label>
                        </div>
                        <div className="form-check form-check_f">
                            <input checked={containerSize === '40’ Standard'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label className="form-check-label" for="flexRadioDefault2">
                                40' Standard
                            </label>
                        </div>
                        <div className="form-check form-check_f">
                            <input checked={containerSize === '40’ High Cube'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                            <label className="form-check-label" for="flexRadioDefault3">
                                40' High Cube
                            </label>
                        </div>
                        <div className="form-check form-check_f">
                            <input checked={containerSize === '20’ Refrigerated'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                            <label className="form-check-label" for="flexRadioDefault4">
                                20’ Refrigerated
                            </label>
                        </div>
                        <div className="form-check form-check_f">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                            <label checked={containerSize === '40’ Refrigerated'} className="form-check-label" for="flexRadioDefault5">
                                40’ Refrigerated
                            </label>
                        </div>
                        <div className="form-check form-check_f">
                            <input checked={containerSize === '45’ High Cube'} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                            <label className="form-check-label" for="flexRadioDefault6">
                                45’ High Cube
                            </label>
                        </div>
                    </div>
                </div>
                <div id='FreightList' className='col-lg-9 col-md-9' style={{width:75+'%'}}>
                <div id='Freights' dangerouslySetInnerHTML={{ __html: freightList }} />
                <TradeFinanceCard />
                <QualityAssuranceCard />
                <br></br>
                <div style={{width:'100%',textAlign:'center'}}>
                <a className="button_booking" onClick={() => checkout()}>Checkout</a> 
                </div>
                </div>
                <svg onClick={freightDropDown} viewBox="25 -25 50 50" style={{ width: '5%', height: '50px', marginTop: '-50px', marginLeft: '-90px', cursor:'pointer' }}>
                <text className="material-icons" x="50%" y="50%" id="freightDropIcon" textAnchor="start" style={{ fontSize: '60px', fill: 'currentColor' }}>
                    arrow_drop_down
                </text>
                </svg>
            </div>
        </div>

    )
}


export default Feature
import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hader from '../components/Header';
import Footer from '../components/Footer';
import '../App.css'
const Green = () => {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    return (
        <>
            <Hader />
            <div className='green_bg'>
                <div className="container">
                    <div className="bg-white rounded p-4">
                        <h2>GreenShipping-2023 asas</h2>

                        <div className="row">
                            <div className='col-lg-6 col-md-6'> <p className="mt-2 "><strong>Contact Name:</strong> Shipping Freight Contract</p></div>
                            <div className='col-lg-6 col-md-6'> <p className="mt-2 "><strong>Contract Money:</strong>  $50,000</p></div>

                        </div>

                        <div className="row">
                            <div className='col-lg-6 col-md-6'> <p className="mt-2 "><strong>Contract From:</strong> Samson96@yahoo.com </p></div>
                            <div className='col-lg-6 col-md-6'> <p className="mt-2 "><strong>Contract To:</strong>  Samson96@yahoo.com </p></div>

                        </div>
                        <div className="row">
                            <div className='col-12 col-lg-10 col-md-10'>
                                <p><strong>Contract Description:</strong> Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled</p></div>
                            <div className='col-`12` col-lg-2 col-md-2'></div>

                        </div>




                        <h5 className="mb-4 mt-4">Conditions</h5>
                        <ul className="tarms_con">
                            <div className="d-flex justify-content-between my-2">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <img src='assets/images/greencheck.svg' alt="" />
                            </div>
                            <div className="d-flex justify-content-between my-2">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <img src='assets/images/greencheck.svg' alt="" />
                            </div>
                            <div className="d-flex justify-content-between my-2">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <img src='assets/images/redcheck.svg' alt="" />
                            </div>
                            <div className="d-flex justify-content-between my-2">
                                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                                <img src='assets/images/greencheck.svg' alt="" />
                            </div>


                        </ul>
                        <div className="container">
                            <button type="button" className="btn btn-primary" onClick={handleShow}>Finish Contract</button>
                        </div>
                    </div>
                </div>
                {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                           
                            <div className="modal-body opt_poup">
                                <div className='icon_poup'>
                                    <img src='assets/images/greenicon.svg'></img>
                                    </div>
                                <h2>Your Contracts Created Successfully!</h2>
                                <p>This contract is success and money is transferred to seller.</p>
                               <div className=''>
                                    <button className='btn btn-Verify' onClick={handleClose}>Okay</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
            </div>
            <Footer />
        </>
    );
};





export default Green;

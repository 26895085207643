// HeroSlider.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

const HeroSlider = () => {
    return (
        <div className='hero_bg'>
        <div className="hero-slider-container">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-6">
                        <div className="hero-text">
                            <h1>Your end-to-end
                                <span > Cross-border trade,</span>  all in
                                one platform</h1>
                            <Link to='/FreightQuote' className="btn btn-primary">Freight Quote <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="hero-image">
                            <img src="assets/images/slide1.png" alt="Hero" className="img-fluid"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default HeroSlider;

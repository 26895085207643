import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const SeaRates = () => {
    return (
        <div className="py-5">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-6 col-md-6">
                      <div className="backgrou_udimg">
                       <h1>Taram</h1>
                       <p>Taram is a one-stop platform facilitating business-to-business cross-border trade.</p>
                       <p>The use of Taram’s proprietary Proof of Quality Delivery protocol (PoQD) facilitates easy, transparent, and secure transactions with reduced costs.</p>
                       <p>Our vision is to empower small businesses to effortlessly navigate the complexities of logistics and cross-border shipments. By making trade financing accessible with just a few clicks, we aim to simplify global trade and enable these enterprises to thrive in a competitive market.</p>
                      </div>
                     </div>
                     <div className="col-lg-6 col-md-6">
                     
                     <img src='assets/images/sipe.png' className='h-50 w-100' />
                     <div className="backgrou_udimg2">
                       <h1>Secure and Fast Transactions</h1>
                       <p>Blockchain enabled transactions focusing on end-to-end security and prevention of risks for our customers.</p>
                      </div>
                     </div>

                 


                </div>
               
            </div>
        </div>

    );
};

export default SeaRates;

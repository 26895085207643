import React, { useState, useEffect } from 'react';
import { writeCookie, readCookie } from '../components/utils';
import { useNavigate } from 'react-router-dom';

const YourComponent = () => {
    const navigate = useNavigate();
    const qualitys = ['Excel Surveyors'];
    const images = ['assets/images/xs.jpg'];
    const credits = ['Loading Inspection', 'Quality Assurance', 'Vendor Audit', 'Comprehensive Service'];
    const qualityCookies = [
      'Loading Inspection',
      'Quality Assurance',
      'Vendor Audit',
      'Comprehensive Service',
    ];
    
    const initialSelectedQualities = qualityCookies.map((cookie) =>
      readCookie(cookie) === 'true' ? 'checked' : ''
    );
    const [contractMoney, setContractMoney] = useState(readCookie('contract_money')?readCookie('contract_money'):0);
    const [loadingInspectionCost, setLoadingInspectionCost] = useState(0);
    const [qualityAssuranceCost, setQualityAssuranceCost] = useState(0);
    const [vendorAuditCost, setVendorAuditCost] = useState(0);
    const [comprehensiveServiceCost, setComprehensiveServiceCost] = useState(0);
    const [qualityCost, setQualityCost] = useState(0);
    const [selectedQualities, setSelectedQualities] = useState(initialSelectedQualities);

    useEffect(() => {
      const money = parseInt(contractMoney);
      let totalQualityCost = 0;
    
      if (selectedQualities[0] === 'checked' && money > 0) {
        setLoadingInspectionCost(330);
        totalQualityCost += 330;
      }
    
      if (selectedQualities[1] === 'checked' && money > 0) {
        setQualityAssuranceCost(Math.max(330, ((0.72 * money) / 100).toFixed(2)));
        totalQualityCost += Math.max(330, ((0.72 * money) / 100).toFixed(2));
      }
    
      if (selectedQualities[2] === 'checked' && money > 0) {
        setVendorAuditCost(150);
        totalQualityCost += 150;
      }
    
      if (selectedQualities[3] === 'checked' && money > 0) {
        setComprehensiveServiceCost(Math.max(660, ((0.9 * money) / 100).toFixed(2)));
        totalQualityCost += Math.max(660, ((0.9 * money) / 100).toFixed(2));
      }
    
      setQualityCost(totalQualityCost);
      writeCookie('quality_cost', totalQualityCost);
    }, [selectedQualities, contractMoney]);
    
    const qualityDropDown = () => {
        var qualityDiv = document.getElementById('qualityDiv');
        var icon = document.getElementById('qualityDropIcon');
        if(qualityDiv.style.display == "none") {
            qualityDiv.style.display = "block";
            icon.innerHTML = "arrow_drop_down";
        } else {
            qualityDiv.style.display = "none";
            icon.innerHTML = "arrow_drop_up";
        }
        
    };

    const handleOptionClick = (index) => {
      var btns = document.getElementsByClassName('select-quality-button');
      var prev = btns[0].innerHTML;
      if(prev.indexOf("SELECTED")==-1) {
        btns[index].innerHTML = "SELECTED";
        writeCookie('quality_cost', qualityCost);
      } else {
        btns[index].innerHTML = "$"+qualityCost;
        writeCookie('quality_cost', 0);
      }
    };
    const selectQuality = (elem, credit, index) => {
      const updatedQualities = [...selectedQualities];
    
      if (elem.target.checked) {
        writeCookie(credit, 'true');
        updatedQualities[index] = 'checked';
      } else {
        writeCookie(credit, 'false');
        updatedQualities[index] = '';
      }
    
      setSelectedQualities(updatedQualities);
    };
  return (
    <div
      className="bubble-element Group baTaHaMy bubble-r-container flex column"
      style={{
        overflow: 'visible',
        justifyContent: 'flex-start',
        borderRadius: '0px',
        opacity: 1,
        alignSelf: 'center',
        minWidth: '280px',
        maxWidth: '100%',
        order: 7,
        minHeight: '10px',
        height: 'max-content',
        flexGrow: 0,
        flexShrink: 0,
        width: 'calc(100% - 0px)',
        margin: '20px 0px 0px',
        zIndex: 9,
      }}
    >
      <div
        className="bubble-element Group baTaHaNaA bubble-r-container flex column"
        style={{
          overflow: 'visible',
          justifyContent: 'flex-start',
          borderRadius: '0px',
          opacity: 1,
          alignSelf: 'flex-start',
          minWidth: '280px',
          order: 1,
          minHeight: '10px',
          height: 'max-content',
          flexGrow: 0,
          flexShrink: 0,
          width: 'calc(100% - 0px)',
          margin: '0px',
          zIndex: 2,
        }}
      >
        <div
          className="bubble-element Group baTaHaOq bubble-r-container flex row"
          style={{
            overflow: 'visible',
            justifyContent: 'space-between',
            borderRadius: '0px',
            opacity: 1,
            alignSelf: 'flex-start',
            minWidth: '0px',
            order: 1,
            minHeight: '0px',
            height: 'max-content',
            flexGrow: 0,
            flexShrink: 0,
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
            zIndex: 17,
          }}
        >
          <div
            className="bubble-element Text baTaHaNaB"
            style={{
              whiteSpace: 'pre-wrap',
              overflow: 'visible',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: 1.4,
              padding: '0px 0px 0px 10px',
              borderRadius: '0px',
              opacity: 1,
              alignSelf: 'center',
              minWidth: '200px',
              maxWidth: '200px',
              order: 1,
              minHeight: '10px',
              width: '200px',
              flexGrow: 1,
              height: 'max-content',
              margin: '0px',
              zIndex: 2,
            }}
          >
            TRADE QUALITY
          </div>
          <button
            className="bubble-element materialicons-Materialicon clickable-element"
            style={{
              alignSelf: 'center',
              minWidth: '45px',
              maxWidth: '45px',
              order: 2,
              minHeight: '45px',
              maxHeight: '45px',
              width: '45px',
              flexGrow: 1,
              height: '45px',
              margin: '0px',
              zIndex: 3,
              padding: '0px',
              textAlign: 'center',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: 'rgb(27, 27, 27)',
              borderRadius: '4px',
            }}
          >
            <svg onClick={qualityDropDown} viewBox="25 -25 50 50" style={{ width: '100%', height: '100%' }}>
              <text className="material-icons" x="50%" y="50%" id="qualityDropIcon" textAnchor="start" style={{ fontSize: '50px', fill: 'currentColor' }}>
                arrow_drop_down
              </text>
            </svg>
          </button>
        </div>
      </div>
      <div
        className="bubble-element Group baTaHaNaD bubble-r-container flex column"
        id="qualityDiv"
        style={{
          alignSelf: 'flex-start',
          minWidth: '280px',
          order: 2,
          height: 'max-content',
          flexGrow: 0,
          flexShrink: 0,
          width: 'calc(100% - 0px)',
          margin: '0px',
          zIndex: 3,
          overflow: 'visible',
          justifyContent: 'flex-start',
          borderRadius: '0px',
          opacity: 1,
        }}
      >
        <div
          className="bubble-element RepeatingGroup baTaHaNaE bubble-rg"
          style={{
            overflow: 'auto',
            gap: '0px',
            gridTemplateRows: 'repeat(3, minmax(max-content, auto))',
            gridTemplateColumns: 'repeat(1, minmax(0px, 1fr))',
            gridAutoFlow: 'row',
            borderRadius: '0px',
            opacity: 1,
            alignSelf: 'center',
            minWidth: '280px',
            order: 2,
            minHeight: '0px',
            height: 'max-content',
            flexGrow: 0,
            flexShrink: 0,
            width: 'calc(100% - 0px)',
            margin: '0px',
            zIndex: 2,
          }}
        >
          {qualitys.map((quality,index) => (
          <div
            className="bubble-element group-item bubble-r-container flex column entry-1"
            style={{
              boxSizing: 'content-box',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="bubble-element Group baTaHaNaM bubble-r-container flex row"
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(170, 170, 170) 2px 2px 8px 0px',
                overflow: 'visible',
                justifyContent: 'space-between',
                gap: '0px',
                borderRadius: '8px',
                padding: '10px',
                opacity: 1,
                alignSelf: 'flex-start',
                minWidth: '0px',
                order: 6,
                minHeight: '0px',
                height: 'max-content',
                flexGrow: 0,
                flexShrink: 0,
                width: 'calc(100% - 60px)',
                margin: '5px 30px',
                zIndex: 12,
              }}
            >
              <div
                className="bubble-element Group baTaHjaO bubble-r-container flex row"
                style={{
                  overflow: 'visible',
                  justifyContent: 'flex-start',
                  gap: '0px',
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '250px',
                  maxWidth: '250px',
                  order: 1,
                  minHeight: '0px',
                  width: '250px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 20,
                }}
              >
                <div
                  className="bubble-element Image baTaHjaR"
                  style={{
                    borderRadius: '0px',
                    opacity: 1,
                    alignSelf: 'flex-start',
                    minWidth: '40px',
                    maxWidth: '100px',
                    order: 1,
                    minHeight: '40px',
                    maxHeight: '40px',
                    width: '100px',
                    flexGrow: 0,
                    flexShrink: 0,
                    height: '40px',
                    margin: '0px',
                    zIndex: 10,
                  }}
                >
                  <img
                    src={images[index]}
                    alt="Bank 1"
                    style={{
                      borderRadius: '8px',
                      opacity: 1,
                      alignSelf: 'flex-start',
                      order: 1,
                      minHeight: '40px',
                      maxHeight: '40px',
                      flexGrow: 0,
                      flexShrink: 0,
                      height: '40px',
                      margin: '0px',
                      zIndex: 15,
                    }}
                  />
                </div>
                <div
                  className="bubble-element Text baTaHjaQ"
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'visible',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.5,
                    borderRadius: '0px',
                    opacity: 1,
                    alignSelf: 'center',
                    minWidth: '250px',
                    maxWidth: '200px',
                    order: 2,
                    minHeight: '10px',
                    width: '200px',
                    flexGrow: 1,
                    height: 'max-content',
                    margin: '0px',
                    zIndex: 14,
                  }}
                >
                  {quality}
                </div>
              </div>
              <div
                className="bubble-element Text baTaHaNaO"
                style={{
                  whiteSpace: 'pre-wrap',
                  overflow: 'visible',
                  fontSize: '14px',
                  lineHeight: 1.5,
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '140px',
                  order: 2,
                  minHeight: '10px',
                  width: '90px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 18,
                }}
              >
                <div className="bubble-element Group baTaHaPt bubble-r-container flex column" style={{ overflow: 'visible', justifyContent: 'flex-start', borderRadius: '0px', opacity: 1, alignSelf: 'center', minWidth: '150px', maxWidth: '150px', order: 6, minHeight: '0px', width: '150px', flexGrow: 1, height: 'max-content', margin: '0px', zIndex: 29 }}>
                
                {credits.map((credit,index) => (
                <div className="bubble-element Checkbox baTaHaPb clickable-element" style={{ alignSelf: 'flex-start', minWidth: '180px', maxWidth: '180px', order: 1, minHeight: '20px', height: 'max-content', flexGrow: 0, flexShrink: 0, width: '180px', margin: '0px', zIndex: 24, fontFamily: 'var(--font_default)', fontSize: '14px', fontWeight: 400, color: 'rgb(27, 27, 27)', lineHeight: 1.5, opacity: 1, marginBottom:'10px' }}>
                  <input onChange={(elem) => selectQuality(elem,credit,index)} type="checkbox" id="1704720773680x10166" checked={selectedQualities[index]} style={{ backgroundColor: 'white', marginRight:'10px', cursor:'pointer' }} />
                  <label htmlFor="1704720773680x10166">{credit}</label>
                </div>
                ))}
              </div>
              </div>
            
              <button
                className="clickable-element bubble-element Button baTaHaNl select-button select-quality-button"
               onClick={() => handleOptionClick(index)}
                style={{
                  backgroundColor: '#155de9',
                  borderRadius: '4px',
                  boxShadow: 'rgb(170, 170, 170) 0px 0px 5px 0px',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '14px',
                  letterSpacing: '0px',
                  textAlign: 'center',
                  textTransform: 'none',
                  overflow: 'hidden',
                  padding: '10px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '100px',
                  maxWidth: '100px',
                  order: 5,
                  minHeight: '40px',
                  height: 'auto',
                  flexGrow: 1,
                  flexShrink: 0,
                  width: '100px',
                  margin: '0px',
                  zIndex: 13,
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                ${qualityCost}
              </button>
            </div>
          </div>
          ))}
          {/* Other entries (similar structure) */}
        </div>
      </div>
    </div>
  );
};

export default YourComponent;

// HeroSlider.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const RightSingleService = () => {
    return (
        <div className="py-0">
        <div className="container">
        <div className="row">
                    <div className="col-md-12 mb-4 text-center">
                        <h1>
                        One Unified Platform Handling <span className="color-blue-h1"> All Your Shipping Needs</span>
                        </h1>
                    </div>
                </div>
            <div className="row">
               
                <div className="col-md-7 col-lg-7">
                    <div className='single-left'>
                        <div className=" single-text single-text-left">
                            <h1>Receive Goods on <span > Time </span>  </h1>
                            <p>Hit your targeted timeline for shipping, optimize your needs, and plan business operations due to the efficiency of using our platform.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="col-md-5 col-lg-5">
                    <div className="serivcs-image_v2">
                        <img src="assets/images/serivcs1.png" alt="Hero" className="img-fluid"
                        />
                    </div>
                </div>

            </div>
        </div>
        </div>

    );
};

export default RightSingleService;

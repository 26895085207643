import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { domain, getLoginInfo } from '../components/utils';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function Contractdetils() {
    const [contracts, setContracts] = useState([]);
    const [currentContract, setCurrentContract] = useState('');
    const [rawContractData, setRawContractData] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [conditionsModal, setConditionsModal] = useState(false);
    const [orderStatusModal, setOrderStatusModal] = useState(false);
    const [finishContractModal, setFinishContractModal] = useState(false);
    const [rawDataModal, setRawDataModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleReviewClose = () => setReviewModal(false);
    const handleConditionClose = () => setConditionsModal(false);
    const handleOrderStatusClose = () => setOrderStatusModal(false);
    const handleFinishContractClose = () => setFinishContractModal(false);
    const handleRawDataClose = () => setRawDataModal(false);
    const showFreightDetails = () => setShowModal(true);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(false);
    const [id, setId] = useState(false);
    const [role, setRole] = useState(0);
    const [jwt, setJwt] = useState('');
    const [freightDetails, setFreightDetails] = useState('');
    const [selectedContract, setSelectedContract] = useState(0);
    const navigate = useNavigate();

    const orderStatusOptions = [
        'Order Confirmation',
        'Production or Procurement',
        'Pre-Shipment Inspection',
        'Packing and Ready for Shipment',
        'Goods Dispatched',
        'In Transit',
        'Customs Clearance',
        'Arrived at Destination',
        'Delivery to Buyer',
        'Delivery Confirmed',
        'Payment Processing',
        'Order Completed',
    ];

    const finishContractOptions = [
        'pending',
        'success',
        'fail'
    ];

    useEffect(() => {
        // Check if jwt cookie is present and not empty
        const userInfo = getLoginInfo();
        if (!userInfo) {
          navigate('/SignIn');
        } else {
          setEmail(userInfo.email);
          setJwt(userInfo.jwt);
          setId(userInfo.id);
          setRole(userInfo.role);
          getAllContracts(userInfo.jwt, userInfo.email, userInfo.id);
        }
    }, []);

    const getAllContracts = (jwt, email, id) => {
        setLoading(true);
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        fetch(`${domain}apis/contracts/getAll.php`, {
            method: 'POST',
            headers
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                setContracts(data);
                if(data.length > 0) {
                    for(var i=0; i < data.length; i++) {
                        if(!data[i].contract_from) {
                            if(data[i].from_id === id) {
                                data[i].contract_from = email;
                                data[i].contract_for = data[i].email;
                            } else {
                                data[i].contract_from = data[i].email;
                                data[i].contract_for = email;
                            }
                        }
                        data[i].description = data[i].description.split('\n');
                        data[i].conditions = JSON.parse(data[i].conditions);
                    }                   
                    setCurrentContract(data[0]);
                    setFreightDetails(JSON.parse(data[0].freight_service));
                } else {
                    setCurrentContract('');
                }            
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    };

    const selectContract = (index) => {
        setSelectedContract(index);
        setCurrentContract(contracts[index]);
        setFreightDetails(JSON.parse(contracts[index].freight_service));
    };

    const reviewCondition = () => {
        setReviewModal(true);
    }

    const reviewSmartCondition = () => {
        setConditionsModal(true);
    }

    const reviewOrderStatus = () => {
        setOrderStatusModal(true);
    }

    const reviewFinishContract = () => {
        setFinishContractModal(true);
    }

    const showRawDataModal = () => {
        setLoading(true);
        fetch(`${domain}apis/contracts/blockchain/getContract.php`, {
            method: 'POST',
            body: JSON.stringify({"cid": currentContract.cid})
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                setRawContractData(JSON.stringify(data, null, 4));
                setRawDataModal(true);     
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    }

    const editContract = () => {
        confirmAlert({
            title: 'Confirm to edit',
            message: 'Are you sure you want to edit this contract? All conditions of the contract will again be pending even if those are already approved!',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    navigate('/EditContract', { state: { data: {cid: currentContract.cid} } });
                },
            },
            {
                label: 'No',
                onClick: () => {
                },
            },
            ],
        });
    }

    const submitContract = () => {
        if(currentContract.approved_status != 'approved') {
            Swal.fire({
                title: 'Error!',
                text: 'The contract must be approved before submiting to blockchain.',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return;
        }
        confirmAlert({
            title: 'Confirm to edit',
            message: 'Are you sure you want to submit this contract? Nobody can edit it once it is submitted to blockchain.',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    const headers = {
                        'Authorization': `Bearer ${jwt}`,
                    };
                    window.scrollTo(0, 0);
                    setLoading(true);
                    fetch(`${domain}apis/contracts/blockchain/createContract.php`, {
                        method: 'POST',
                        headers,
                        body: JSON.stringify({ cid: currentContract.cid }),
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        setLoading(false);
                        if(data.status == 'fail') {
                            Swal.fire({
                                title: 'Error!',
                                text: data.message,
                                icon: 'error',
                                confirmButtonText: 'Ok',
                            });
                        } else {
                            Swal.fire({
                            title: 'Success!',
                            text: data.message,
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            }).then(() => {
                            navigate('/ContractDetails');
                            });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error('Error Creating Contract:', error);
                    });
                },
            },
            {
                label: 'No',
                onClick: () => {
                },
            },
            ],
        });
    }

    const createContract = () => {
        navigate('/createContract');
    };

    const handleChat = () => {
        const relativePath = '/Chat?cid='+currentContract.cid; // Replace with your actual relative path
        const newTab = window.open(relativePath, '_blank');
        if (newTab) {
        newTab.focus();
        }
    };

    const approveContract = () => {
        confirmAlert({
        title: 'Confirm to submit',
        message: 'Are you sure you want to approve this contract? Make sure you reviewed all the conditions carefully!',
        buttons: [
        {
            label: 'Yes',
            onClick: () => {
            setLoading(true);
            const headers = {
                'Authorization': `Bearer ${jwt}`,
            };
            fetch(`${domain}apis/contracts/approveContract.php`, {
                method: 'POST',
                headers,
                body: JSON.stringify({"cid": currentContract.cid})
            })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                if(data.status == 'fail') {
                    Swal.fire({
                        title: 'Error!',
                        text: data.message,
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'Ok',
                    });
                    currentContract.approved_status = "approved";
                    setCurrentContract({
                        ...currentContract,
                        approved_status: "approved",
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error('Error Creating Contract:', error);
            });
            },
        },
        {
            label: 'No',
            onClick: () => {
            },
        },
        ],
        });
    }

    const changeCondition = (event, index) => {
        const isChecked = event.target.checked;
        if(isChecked) {
            currentContract.conditions[index].status = "approved";
        } else {
            currentContract.conditions[index].status = "pending";
        }
        const updatedConditions = currentContract.conditions.map((condition, i) =>
            i === index ? { ...condition, status: isChecked ? 'approved' : 'pending' } : condition
        );
        setCurrentContract({
            ...currentContract,
            conditions: updatedConditions,
            approved_status: "pending",
        });
    }

    const changeSmartContractCondition = (event, index) => {
        const isChecked = event.target.checked;
        if(isChecked) {
            currentContract.conditions[index].status = "success";
        } else {
            currentContract.conditions[index].status = "submitted";
        }
        const updatedConditions = currentContract.conditions.map((condition, i) =>
            i === index ? { ...condition, status: isChecked ? 'success' : 'submitted' } : condition
        );
        setCurrentContract({
            ...currentContract,
            conditions: updatedConditions
        });
    }

    const handleOrderStatusChange = (event, index) => {
        const selectedStatus = event.target.value;
        setCurrentContract({
            ...currentContract,
            order_status: selectedStatus
        });
    }

    const handleFinishContractChange = (event, index) => {
        const selectedStatus = event.target.value;
        setCurrentContract({
            ...currentContract,
            success_status: selectedStatus
        });
    }

    const saveReviewCondition = () => {
        setLoading(true);
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        window.scrollTo(0, 0);
        fetch(`${domain}apis/contracts/editConditionsStatus.php`, {
            method: 'POST',
            headers,
            body: JSON.stringify({"cid": currentContract.cid, "conditions": currentContract.conditions})
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });
                setReviewModal(false);       
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    }

    const saveSmartContractCondition = () => {
        setLoading(true);
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        window.scrollTo(0, 0);
        setConditionsModal(false); 
        fetch(`${domain}apis/contracts/blockchain/editConditions.php`, {
            method: 'POST',
            headers,
            body: JSON.stringify({"cid": currentContract.cid, "conditions": currentContract.conditions})
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });                 
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    }

    const saveSmartContractOrderStatus = () => {
        setLoading(true);
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        window.scrollTo(0, 0);
        setOrderStatusModal(false); 
        fetch(`${domain}apis/contracts/blockchain/editOrderStatus.php`, {
            method: 'POST',
            headers,
            body: JSON.stringify({"cid": currentContract.cid, "order_status": currentContract.order_status})
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });                 
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    }

    const finishSmartContract = () => {
        setLoading(true);
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        window.scrollTo(0, 0);
        setFinishContractModal(false); 
        fetch(`${domain}apis/contracts/blockchain/finishContract.php`, {
            method: 'POST',
            headers,
            body: JSON.stringify({"cid": currentContract.cid, "success_status": currentContract.success_status})
        })
        .then((response) => response.json())
        .then((data) => {
            setLoading(false);
            if(data.status == 'fail') {
                Swal.fire({
                    title: 'Error!',
                    text: data.message,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                });
            } else {
                Swal.fire({
                    title: 'Success!',
                    text: data.message,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                });                 
            }
        })
        .catch((error) => {
            setLoading(false);
            console.error('Error Creating Contract:', error);
        });
    }

    return (
        <>
            <Header />
            <div className={`${loading ? 'loading-background' : 'body_f py-55'}`}>
                {loading && <div className="loading-indicator"><img src = './loading.gif'></img></div>}
                <div className={`${loading ? 'loadingcontainer' : 'container'}`}>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 feature_fliter_box p-4'>
                        {contracts.map((contract, index) => (
                            <React.Fragment key={index}>
                            <div onClick={() => selectContract(index)} className={`contract-info ${selectedContract === index && 'selected'}`}>
                                <h2>{contract.contract_name}</h2>
                                <div>
                                    <div className='booking'>
                                    </div>
                                    <div className='booking'>
                                        <div className='d-flex'>
                                            <img src='assets/images/dollar.svg' className='px-2'></img> <span>{contract.contract_cost}</span>
                                        </div>
                                    </div>
                                    <div className='booking'>
                                        <div className='d-flex'>
                                            <img src='assets/images/calendar_f.svg' className='px-2'></img> <span>{contract.creation_date}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <hr />
                            </React.Fragment>
                        ))}
                        {role == 0 && (
                        <button className='btn btn-outline-primary mx-2' onClick={createContract}>Create a new contract</button>
                        )}
                        </div>
                        {currentContract && (
                        <div className='col-lg-9 col-md-9'>
                            <div className='feature_box p-4 pb-0 mb-2 '>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <h6 className='feature_box1' style={{float:'left'}}>Contract Details</h6>
                                        {currentContract.submitted_to_smart_contract === 'submitted' && (
                                        <h6 className='feature_box1 raw-smart-contract' onClick={showRawDataModal}>Raw smart contract data</h6>
                                        )}
                                        <h6 style={{float:'right'}}>
                                        <button className='btn btn-outline-primary mx-2' onClick={handleChat}>Chat</button>
                                        </h6>
                                    </div>
                                    <div>
                                        <br></br>
                                    </div>
                                    <div className="d-flex justify-content-between ">
                                        <div className="Name_cont">
                                            <span>Contract Name</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.contract_name}</h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>
                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract Money</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>USD {currentContract.contract_cost} </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>
                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Advance Money</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>USD {currentContract.advance_cost} </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>
                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract Creator</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.contract_from} </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>
                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract For</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.contract_for} </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Freight Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                        <h6>
                                        {currentContract.freight_total_cost !== "0" ? (
                                            <>
                                            USD {currentContract.freight_total_cost}
                                            <h5 className='sh_owde' onClick={showFreightDetails}>
                                                Show details
                                            </h5>
                                            </>
                                        ) : (
                                            'Not Opt-in'
                                        )}
                                        </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Loading Inspection Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.loading_inspection_cost!="0"
                                                ? `USD ${currentContract.loading_inspection_cost}`
                                                : 'Not Opt-in'}
                                            </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Quality Assurance Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.quality_assurance_cost!="0"
                                                ? `USD ${currentContract.quality_assurance_cost}`
                                                : 'Not Opt-in'}
                                            </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Vendor Audit Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.vendor_audit_cost!="0"
                                                ? `USD ${currentContract.vendor_audit_cost}`
                                                : 'Not Opt-in'}
                                            </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Comprehensive Service Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.comprehensive_service_cost!="0"
                                                ? `USD ${currentContract.comprehensive_service_cost}`
                                                : 'Not Opt-in'}
                                            </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Platform Cost</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.platform_cost!="0"
                                                ? `USD ${currentContract.platform_cost}`
                                                : 'Not Opt-in'}
                                            </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract Creation Date</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.creation_date} </h6>
                                        </div>
                                    </div>
                                    <div><hr /></div>

                                    <div className="col-lg-12 pt-4">
                                        <h1 className='feature_box1'>Contract description.</h1>
                                        <div>
                                        <br></br>
                                    </div>
                                        <p className='descri_ption'>
                                        {currentContract.description.map((line, index) => (
                                            // Use a React fragment or div to represent each line
                                            <React.Fragment key={index}>
                                            {line}
                                            {index < currentContract.description.length - 1 && <br />} {/* Add <br> except for the last line */}
                                            </React.Fragment>
                                        ))}
                                        </p>
                                    </div>
                                    <div className="col-lg-12 pt-4">
                                        <h1 className='feature_box1'>Contract Conditions</h1>
                                        <div>
                                        <br></br>
                                        </div>
                                        {currentContract.conditions.map((condition, index) => (
                                        <div className="d-flex justify-content-between pt-2">
                                            <div>
                                                <li className='descri_ption'>{condition.desc}</li>
                                            </div>
                                            <div >
                                            {condition.status === 'pending' ? (
                                                <img alt="pending" className='condition-check' src='assets/images/redcheck.svg' />
                                            ) : condition.status === 'approved' ? (
                                                <img alt="approved" className='condition-check' src='assets/images/greencheck.svg' />
                                            ) : condition.status === 'submitted' ? (
                                                <img alt="pending" className='condition-check' src='assets/images/pending.png' />
                                            ) : (
                                                <img alt="success" className='condition-check' src='assets/images/success.png' />
                                            )}
                                            </div>
                                        </div>
                                        ))}
                                        <div className="d-flex justify-content-between pt-2 pt-4">
                                        </div>
                                        <h1 className='feature_box1'>Contract Status</h1>
                                        <div>
                                        <br></br>
                                        </div>
                                        <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Approved Status</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.approved_status} </h6>
                                        </div>
                                        </div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Submited to Blockchain</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.submitted_to_smart_contract} </h6>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract's Success Status</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.success_status} </h6>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between pt-1">
                                        <div className="Name_cont">
                                            <span>Contract's Order Status</span>
                                        </div>
                                        <div className="Name_contet ">
                                            <h6>{currentContract.order_status} </h6>
                                        </div>
                                    </div>


                                        <div className="d-flex justify-content-between pt-2 pt-4">
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        )}
                        {!currentContract && (
                            <div className='col-lg-9 col-md-9'>
                            <div className='feature_box p-4 pb-0 mb-2 '>
                                <div className='row'>
                                    <div className="col-lg-12">
                                        <h6 className='feature_box1' style={{float:'left'}}>You don't have any contract yet!</h6>
                                    </div>
                                </div>
                            </div>
                            </div>
                        )}
                        {currentContract && (
                        <div className={`${role==0 ?('col-lg-10') : ('col-lg-44')}`}>
                            <div className="d-flex justify-content-center mt-2">
                            {role == 0 && (
                           <React.Fragment>
                            {currentContract.to_id === id ? (
                                <React.Fragment>
                                <button className={`btn btn-outline-primary mx-2 ${currentContract.submitted_to_smart_contract === 'submitted' && 'disabled'}`} onClick={approveContract} >Approve Contract</button>
                                <button className={`btn btn-outline-primary mx-2 ${currentContract.submitted_to_smart_contract === 'submitted' && 'disabled'}`} onClick={reviewCondition}>Review Conditions</button>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                <button className={`btn btn-outline-primary mx-2 ${currentContract.submitted_to_smart_contract === 'submitted' && 'disabled'}`} onClick={submitContract}>Submit to blockchain</button>
                                <button className={`btn btn-outline-primary mx-2 ${currentContract.submitted_to_smart_contract === 'submitted' && 'disabled'}`} onClick={editContract}>Edit Contract</button>
                                </React.Fragment>
                            )}
                            </React.Fragment>
                            )}
                            {role == 1 && currentContract.submitted_to_smart_contract === 'submitted' && (
                                <React.Fragment>
                                <button className={`btn btn-outline-primary mx-2`} onClick={reviewSmartCondition} >Update Conditions</button>
                                <button className={`btn btn-outline-primary mx-2`} onClick={reviewOrderStatus}>Update Order Status</button>
                                <button className={`btn btn-outline-primary mx-2`} onClick={reviewFinishContract}>Finish Contract</button>
                                </React.Fragment>
                            )}
                            </div>
                        </div>
                        )}
                        
                    </div>
                </div>
                <br></br>
            </div>
        {showModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content p-4 pt-2">
              <div className="modal-header border-0">
                <h5 className="modal-title popover_title" id="exampleModalLabel">Shipment Information</h5>
                <button type="button" className="btn-close" onClick={handleClose}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-location-dot icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text ml-2 ">DEPARTURE <br /><span className="down_heading">{freightDetails.from_city}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-location-dot icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">ARRIVAL <br /><span className="down_heading">{freightDetails.to_city}</span>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6 ">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-calendar-days icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">READY TO LOAD <br /><span clclassNameass="down_heading">{freightDetails.shipping_date}</span></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <div className="icon_box">
                        <i className="fa-solid fa-calendar-days icon_background_style"></i>
                      </div>
                      <div className="text_modal">
                        <p className="top_text">TYPE OF DELIVERY <br /><span className="down_heading">FCL</span>
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="cargo_all">
                  <p className="cargo_heading mt-4 mb-0">Freight Name</p>
                  <div className="d-flex">
                    <h3 className="standard_20 me-3 mt-2">{freightDetails.freight_name}</h3>
                  </div>
                </div>

                <div className="cargo_all">
                  <p className="cargo_heading mt-4 mb-0">Shipping Time</p>
                  <div className="d-flex">
                    <h3 className="standard_20 me-3 mt-2">{freightDetails.freight_days} days</h3>
                  </div>
                </div>
                <div><hr /></div>

                <div className="d-flex costs_group_2 d-flex justify-content-between ">
                  <div>
                    <p className="mt-4 total_costs mb-4">Total Costs</p>
                  </div>
                  <div>
                    <button type="button" className="btn btn-primary mt-4 ms-4 mb-4">USD {freightDetails.freight_cost}</button>
                  </div>
                </div>
                </div>
                </div>
                </div>
                </div>
            )}

        {reviewModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content p-4 pt-2">
              <div className="modal-header border-0">
                <h5 className="modal-title popover_title" id="exampleModalLabel">Select the conditions you agreed on</h5>
                <button type="button" className="btn-close" onClick={handleReviewClose}></button>
              </div>
              <div className="modal-body">

              {currentContract.conditions.map((condition, index) => (
                <div className='col-lg-12 col-md-12'>
                <div className='justify-content-between'>
                    <div className='toggle_icon_c'>

                    <div className="col-md-10">
                        <input type="text" value={condition.desc} className="form-control inpu_tbg  border-0" disabled />
                    </div>

                    <div className="col-md-1 review-conditions-div">
                    <div className="form-check form-switch form-check_c mt-1">
                        <input
                        className="form-check-input form-check-input_c"
                        onChange={(event) => changeCondition(event, index)}
                        type="checkbox"
                        checked={condition.status !== 'pending'}
                        />
                    </div>
                    </div>
                    
                    </div>
                    <div><hr /></div>
                </div>
                </div>
              ))}
            <React.Fragment>
            <button className='btn btn-outline-primary mx-2' onClick={handleReviewClose}>Discard Changes</button>
            <button className='btn btn-outline-primary mx-2' onClick={saveReviewCondition}>Save Changes</button>
            </React.Fragment>       
            </div>
            </div>
            </div>
            </div>
            )}

        {conditionsModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered ">
            <div className="modal-content p-4 pt-2">
              <div className="modal-header border-0">
                <h5 className="modal-title popover_title" id="exampleModalLabel">Select the conditions those are successfully done</h5>
                <button type="button" className="btn-close" onClick={handleConditionClose}></button>
              </div>
              <div className="modal-body">

              {currentContract.conditions.map((condition, index) => (
                <div className='col-lg-12 col-md-12'>
                <div className='justify-content-between'>
                    <div className='toggle_icon_c'>

                    <div className="col-md-10">
                        <input type="text" value={condition.desc} className="form-control inpu_tbg  border-0" disabled />
                    </div>

                    <div className="col-md-1 review-conditions-div">
                    <div className="form-check form-switch form-check_c mt-1">
                        <input
                        className="form-check-input form-check-input_c"
                        onChange={(event) => changeSmartContractCondition(event, index)}
                        type="checkbox"
                        checked={condition.status !== 'submitted'}
                        />
                    </div>
                    </div>
                    
                    </div>
                    <div><hr /></div>
                </div>
                </div>
              ))}
            <React.Fragment>
            <button className='btn btn-outline-primary mx-2' onClick={handleConditionClose}>Discard Changes</button>
            <button className='btn btn-outline-primary mx-2' onClick={saveSmartContractCondition}>Save Changes</button>
            </React.Fragment>    
            </div>
            </div>
            </div>
            </div>
            )}

        {orderStatusModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered ">
        <div className="modal-content p-4 pt-2">
            <div className="modal-header border-0">
            <h5 className="modal-title popover_title" id="exampleModalLabel">Change the current order status of the contract</h5>
            <button type="button" className="btn-close" onClick={handleOrderStatusClose}></button>
            </div>
            <div className="modal-body">
            <div className='col-lg-12 col-md-12'>
            <div className='justify-content-between'>
                <div className='toggle_icon_c'>

                <div className="col-md-10">
                    <select value={currentContract.order_status} onChange={handleOrderStatusChange}>
                    {orderStatusOptions.map((status) => (
                        <option key={status} value={status}>
                        {status}
                        </option>
                    ))}
                    </select>
                </div>
                
                </div>
                <div><hr /></div>
            </div>
            </div>
        <React.Fragment>
        <button className='btn btn-outline-primary mx-2' onClick={handleOrderStatusClose}>Discard Changes</button>
        <button className='btn btn-outline-primary mx-2' onClick={saveSmartContractOrderStatus}>Save Changes</button>
        </React.Fragment>    
        </div>
        </div>
        </div>
        </div>
        )}

        {finishContractModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered ">
        <div className="modal-content p-4 pt-2">
            <div className="modal-header border-0">
            <h5 className="modal-title popover_title" id="exampleModalLabel">Change the current order status of the contract</h5>
            <button type="button" className="btn-close" onClick={handleFinishContractClose}></button>
            </div>
            <div className="modal-body">
            <div className='col-lg-12 col-md-12'>
            <div className='justify-content-between'>
                <div className='toggle_icon_c'>

                <div className="col-md-10">
                    <select value={currentContract.success_status} onChange={handleFinishContractChange}>
                    {finishContractOptions.map((status) => (
                        <option key={status} value={status}>
                        {status}
                        </option>
                    ))}
                    </select>
                </div>
                
                </div>
                <div><hr /></div>
            </div>
            </div>
        <React.Fragment>
        <button className='btn btn-outline-primary mx-2' onClick={handleFinishContractClose}>Discard Changes</button>
        <button className='btn btn-outline-primary mx-2' onClick={finishSmartContract}>Save Changes</button>
        </React.Fragment>    
        </div>
        </div>
        </div>
        </div>
        )}

        {rawDataModal && (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered ">
        <div className="modal-content p-4 pt-2">
            <div className="modal-header border-0">
            <h5 className="modal-title popover_title" id="exampleModalLabel">Raw Smart contract data stored in blockchain</h5>
            <button type="button" className="btn-close" onClick={handleRawDataClose}></button>
            </div>
            <div className="modal-body">
            <div className='col-lg-12 col-md-12'>
            <div className='justify-content-between' style={{overflow:'auto'}}>
                <div className='toggle_icon_c'>

                <div className="col-md-10">
                    {rawContractData}
                </div>
                
                </div>
                <div><hr /></div>
            </div>
            </div>
        <React.Fragment>
        <button className='btn btn-outline-primary mx-2' onClick={handleRawDataClose}>Close</button>
        </React.Fragment>    
        </div>
        </div>
        </div>
        </div>
        )}
            
            <Footer />
        </>

    )
}


export default Contractdetils
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hader from '../components/Header';
import Footer from '../components/Footer';
const Seller = () => {

    return (
        <>
            <Hader />
            <div className='Green_bg mt-5 pt-5'>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-6 mt-3 saller">
                            <h1>
                                Are you a buyer or seller?
                            </h1>
                            <p >Lorem Ipsum is simply dummy text of the printing and typesett<br />
                                Lorem Ipsum has been the industry</p>

                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <button type="button" className="btn btn-primary bt_byar"> <i className="fa-solid fa-bag-shopping"></i> Buyer</button>
                                </div>

                                <div className="col-md-6">
                                    <button type="button" className="btn btt bt_byar"> <i className="fa-solid fa-tag"></i> </button>
                                </div>
                            </div>

                            <label htmlFor="text" className="mb-2 l mt-5">Send Invitation</label><br />
                            <input type="text" id="text" className="rounded-input saller_input" defaultValue="Cheyenne_Wehner@gmail.com" /><br />

                            <div className="d-grid gap-2 mt-4">
                                <button type="button" className="btn btn-primary reg">Send invitation</button>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <img src='assets/images/saller.png' className="w-100" height="700" alt="Rectangle 5025" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};




export default Seller;

import React from 'react';

function Advantages() {
    return (

     <div className='py-4'>
           <div className="container">
            <div className="row ">
                <div className="col-md-12 mb-4 text-center py-4">
                    <h1>
                        Our Advantages
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 ">
                    <div className=" d-flex justify-content-md-end justify-content-center">
                        <img src='assets/images/advantages.svg' alt="" width={'100px'} />
                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="ico_border border_mobile">
                        <h4 className='ek_borderbg d-flex justify-content-center text-white'>1</h4>
                        <h4>One Stop Platform</h4>
                        <p>Taram facilitates shopping of all the associated services to perform a business-to-business cross border trade. Our one stop platform constitutes of Freight booking services, quality assurance services, and trade finance services, thereby providing a hassle-free experience.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 ">
                    <div className=" pt-5">
                        <h4 className='ek_borderbg d-flex justify-content-center text-white'>2</h4>
                        <h4>Smart Contracts</h4>
                        <p>Our proprietary Proof of Quality Delivery (PoQD) uses blockchain to form a smart contract. The PoQD facilitates a secure contract between our customer and all the associate vendors. The digital contract ensures accuracy, transparency, speed and efficiency.</p>
                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="ico_border  border_mobile pt-5 d-flex  justify-content-md-start justify-content-center ">
                        <img src='assets/images/dasktop.svg' alt="" width={'100px'} />  </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className=" d-flex justify-content-md-end justify-content-center pt-5">
                        <img src='assets/images/tvimage.svg' width={'100px'} />
                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="ico_border pt-5 border_mobile">
                        <h4 className='ek_borderbg d-flex justify-content-center text-white'>3</h4>
                        <h4>Fast and Secure Transaction</h4>
                        <p>Money transfer across the border is done using blockchain and escrow. This method used by Taram ensure transactions to be performed within 30 minutes across any two countries. Additionally, it ensures safe remittance of the money, preventing fraudulent transactions.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6  ">
                    <div className=" pt-5">
                        <h4 className='ek_borderbg d-flex justify-content-center text-white'>4</h4>
                        <h4>Document Exchange</h4>
                        <p>Blockchain based smart contracts enables the digital swapping of title documents of the transaction. This optimizes the existing manual process using couriers and fax machines, thus enabling our customers to receive documents promptly, work efficiently and hence reducing costs.</p>

                    </div>
                </div>
                <div className="col-md-6 ">
                    <div className="ico_border d-flex justify-content-md-start justify-content-center border_mobile">
                        <div className="pt-5">
                            <img src='assets/images/relode.svg' alt="" width={'100px'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>

    );
}

export default Advantages;
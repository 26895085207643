import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import '../App.css'
function AboutUs() {
  return (
    <>
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      <Contact />
      <br></br>
      <Footer />
      </div>
    </>
  );
}

export default AboutUs;

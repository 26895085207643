import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Deals from '../components/Deals';
import Leads from '../components/Leads';
import FreightQuote from '../components/FreightQuote';
import '../App.css'
import Services from './Services';
import SeaRates from './SeaRates';
import LogisticsPlatform from '../components/LogisticsPlatform';
import OurBlog from '../components/OurBlog';
import Advantages from '../components/Advantages';
import Carouselslider from '../components/Carouselslider';
function Taramtow() {
  return (
    <>
      <div style={{ overflowX: 'hidden' }}>
        <Header />
        <FreightQuote />
        <Deals />
        {/* <Carouselslider /> */}
        <Services />
        <SeaRates />
        <Advantages />
        <div className='pt-5'>
          <LogisticsPlatform />
        </div>
        <br></br>
        <Footer />
      </div>
    </>
  );
}

export default Taramtow;

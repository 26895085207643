import React from 'react'

function Sitemap() {
  return (
    <div>
      <div className="container">
        <div className='row'>
          <div className='col-lg-12 col-md-12 text-center map_ayra pb-5'>
            <h1>Global Reach, Local On-the-Ground <span>Expertise</span></h1>
          </div>
        </div>
      <div className='row'>
      <div className='col-md-4 mb-4'> 
      <div className='map_image'>
        <img src = "assets/images/map1.png"></img>
        <br></br>
        159, Gopathi Narayanaswami Chetty Rd, Chennai, Tamil Nadu 600017, India
      </div>
      </div>
      <div className='col-md-4 mb-4'>
      <div className='map_image'>
        <img src = "assets/images/map2.png"></img>
        <br></br>
        14 Ibiza Aisle, Irvine, CA 92514, USA
        </div> 
      </div>
      <div className='col-md-4 mb-4'>
      <div className='map_image'>
        <img src = "assets/images/map3.png"></img>
        <br></br>
        Sokolovska 27/93, Karlin,Prague Czech republic -18600
        </div> 
      </div>
      </div>
      </div>

    </div>
  )
}

export default Sitemap
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { writeCookie, readCookie } from '../components/utils';

const Ticket = () => {
    const [location, setLocation] = useState('');
    const [toLocation, setToLocation] = useState('');
    const [searchText, setSearchText] = useState(readCookie('from_location'));
    const [contractMoney, setContractMoney] = useState(readCookie('contract_money'));
    const [searchToText, setSearchToText] = useState(readCookie('to_location'));
    const [fromLat, setFromLat] = useState(readCookie('from_lat') || 0);
    const [fromLng, setFromLng] = useState(readCookie('from_lng') || 0);
    const [toLat, setToLat] = useState(readCookie('to_lat') || 0);
    const [toLng, setToLng] = useState(readCookie('to_lng') || 0);
    const [containerSize, setContainerSize] = useState(readCookie('container_size')?readCookie('container_size'):'20’ Standard');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showToDropdown, setShowToDropdown] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date()); // Set default date to today
    const [showPopup, setShowPopup] = useState(false);
    const [containerLength, setContainerLength] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const [containerWeight, setContainerWeight] = useState(readCookie('container_weight')?readCookie('container_weight'):'1');
    const [activeTab, setActiveTab] = useState('tab1');
    const [lastUpdatedTextbox, setLastUpdatedTextbox] = useState(null);
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(true);

    const containerOptions = [
        '20’ Standard',
        '40’ Standard',
        '40’ High Cube',
        '20’ Refrigerated',
        '40’ Refrigerated',
        '45’ High Cube'
    ];

    const handleContainerChange = (event, index) => {
        const selectedContainer = event.target.value;
        setContainerSize(selectedContainer);
    }
    
    const navigate = useNavigate();
    useEffect(() => {
        // Function to fetch data from API
        const fetchData = async () => {
            try {
              const queryParam = lastUpdatedTextbox === 'searchToText' ? `${searchToText}` : `${searchText}`;
              const response = await fetch(`https://secure.geonames.org/searchJSON?q=${queryParam}&maxRows=4&username=sarathisahoo`, {
              });
      
              const data = await response.json();
              let cities = [];
              for(var i=0; i < data.geonames.length; i++) {
                if(data.geonames[i].lat && data.geonames[i].lat!="") {
                    cities.push(data.geonames[i]);
                }
              }
              if (Array.isArray(cities)) {
                setLocation(prev => [...cities]);
                setToLocation(prev => [...cities]);
              } else {
                setLocation([]);
                setToLocation([]);
              };
            } catch (error) {
              console.error('Error fetching data:', error);
            }
        };
    
        // Debounce function to limit API calls
        const debounce = (func, delay) => {
          let timeoutId;
          return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
          };
        };
    
        // Call fetchData with debounced input after 1 second pause
        setLocation([]);
        setToLocation([]);
        const debouncedFetch = debounce(fetchData, 400);
        debouncedFetch();
    
        // Clean up the timeout on component unmount
        return () => clearTimeout(debouncedFetch);
    }, [searchText, searchToText]);
    
    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleFromLocationChange = (event) => {
        const input = event.target.value;
        setLastUpdatedTextbox('searchText');
        setSearchText(input);
        setShowToDropdown(false);
        setIsDropdownOpen(true);    
    };
    const handleFromLocationEnter = (event) => {
        if (event.key === 'Enter' && location.length > 0) {
            setSearchText(location[0].name+', '+location[0].countryCode);
            setFromLat(location[0].lat);
            setFromLng(location[0].lng);
            setIsDropdownOpen(false);
        }
    };

    const handleContractMoney = (event) => {
        const input = event.target.value;
        setContractMoney(input); 
    };

    const handleContainerWeight = (event) => {
        const input = event.target.value;
        setContainerWeight(input); 
    };

    const handleToLocationChange = (event) => {
        const input = event.target.value;
        setLastUpdatedTextbox('searchToText');
        setSearchToText(input);
        setIsDropdownOpen(false);
        setShowToDropdown(true);
    };
    const handleToLocationEnter = (event) => {
        if (event.key === 'Enter' && toLocation.length > 0) {
            setSearchToText(toLocation[0].name+', '+toLocation[0].countryCode);
            setToLat(toLocation[0].lat);
            setToLng(toLocation[0].lng);
            setShowToDropdown(false);
        }
    };

    const handleToLocationSelect = (selectedLocation, lat, lng) => {
        setToLat(lat);
        setToLng(lng);
        setSearchToText(selectedLocation);
        setShowToDropdown(false);
    };

    const handleOptionClick = (selectedValue, lat, lng) => {
        setFromLat(lat);
        setFromLng(lng);
        setSearchText(selectedValue);
        setIsDropdownOpen(false);
    };
    const handleSelectButtonClick = () => {
        setShowPopup(false);
    };

    const handleSearchButtonClick = () => {
        let error_msg="";
        let from_location = document.getElementById('from_location').value;
        if(from_location.trim()==="") {
            error_msg = "Please enter from location";
        }
        
        let to_location = document.getElementById('to_location').value;
        if(to_location.trim()==="") {
            error_msg = "Please enter to location";
        }

        let freight_date = selectedDate ? selectedDate.toISOString().split('T')[0] : '';
        if(freight_date==="") {
            error_msg = "Please enter freight date";
        }
        let money = contractMoney;
        if(money && money.trim()==="") {
            error_msg = "Please enter a valid Good Value";
        }
        if(!money) {
            error_msg = "Please enter a valid Good Value";
        }

        if(error_msg!="") {
            Swal.fire({
                title: 'Error!',
                text: error_msg,
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return;
        }
        writeCookie('contract_money', money);
        writeCookie('container_size', containerSize);
        writeCookie('container_weight', containerWeight);
        writeCookie('from_location', from_location);
        writeCookie('to_location', to_location);
        writeCookie('freight_date', freight_date);
        writeCookie('from_lat', fromLat);
        writeCookie('from_lng', fromLng);
        writeCookie('to_lat', toLat);
        writeCookie('to_lng', toLng);

        let freight_type = 'ocean';
        if(activeTab === 'tab2') {
            freight_type = 'land';
        } else if(activeTab === 'tab3') {
            freight_type = 'air';
        }
        writeCookie('freight_type', freight_type);     

        let container_size = 'ST20';
        if(containerSize === '40’ Standard') {
            container_size = 'ST40';
        } else if (containerSize === '40’ High Cube') {
            container_size = 'HQ40';
        } else if (containerSize === '20’ Refrigerated') {
            container_size = 'REF20';
        } else if (containerSize === '40’ Refrigerated') {
            container_size = 'REF40';
        } else if (containerSize === '45’ High Cube') {
            container_size = 'HQ45';
        }

        const dataToSend = { from_countrycode: from_location.split(',')[1].trim(), from_city: from_location.split(',')[0].trim(), to_countrycode: to_location.split(',')[1].trim(), to_city: to_location.split(',')[0].trim(), shipping_date: freight_date, length: containerLength, width: containerWidth, height: containerHeight, weight: containerWeight, from_lat: fromLat, from_lng: fromLng, to_lat: toLat, to_lng: toLng, container_size: container_size, freight_type: freight_type };
        navigate('/FreightList', { state: { data: dataToSend } });       
      };

    return (
        <div className="pt-5 fixtop">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-6 col-md-6 col-12 mx-auto text-center pb-3" style={{display:'none',width:60+'%'}}>
                        <div className="btn-group btn-group_2">
                            <button type="button" className="btn active">Rates</button>
                            <button type="button" className="btn">Tracking</button>
                            <button type="button" className="btn">Schedules</button>
                        </div>
                        <div className="btn-group">
                            <button type="button" className="btn btn-dark_req">Request a quote  <i className="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className="bg_find">
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <div className="d-flex mobile">
                                <div className="icon_lo">
                                    <img src="assets/images/map.svg"/>
                                </div>
                                <div className="lo-dropdown">
                                    <h4>From</h4>
                                    <input
                                        className='input_lo'
                                        id='from_location'
                                        type='text'
                                        placeholder='Enter your location'
                                        value={searchText}
                                        onChange={handleFromLocationChange}
                                        onKeyUp={handleFromLocationEnter}
                                        autocomplete="off"
                                    />
                                    {isDropdownOpen && location.length > 0 && (
                                        <ul className="boxDropdown">
                                        {location.map((loc) => (
                                          <li key={loc.name} onClick={() => handleOptionClick(loc.name+', '+loc.countryCode, loc.lat, loc.lng)}>{loc.name+', '+loc.countryCode}</li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                            <div className="d-flex mobile">
                                <div className="icon_lo">
                                    <img src='assets/images/map.svg' alt="map icon" />
                                </div>
                                <div className="lo-dropdown">
                                    <h4>To</h4>
                                    <input
                                        className='input_lo'
                                        id='to_location'
                                        type='text'
                                        placeholder='Enter your location'
                                        value={searchToText}
                                        onChange={handleToLocationChange}
                                        onKeyUp={handleToLocationEnter}
                                        autocomplete="off"
                                    />

                                    {showToDropdown && toLocation.length > 0 && (
                                        <ul className="boxDropdown">
                                        {toLocation.map((loc) => (
                                          <li key={loc.name} onClick={() => handleToLocationSelect(loc.name+', '+loc.countryCode, loc.lat, loc.lng)}>{loc.name+', '+loc.countryCode}</li>
                                        ))}
                                      </ul>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                            <div className="d-flex mobile">
                                <div className="icon_lo">
                                      <img src='assets/images/calendar.svg' alt="map icon" />
                                </div>
                                <div className="lo-dropdown">
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        dateFormat="dd MMMM yyyy"
                                        placeholderText="Select a date"
                                        className='input_lo'
                                        id='freight_date'
                                        customInput={<CustomDatePickerInput />}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3">
                            <div className="d-flex mobile justify-content-between">
                                <div className="d-flex fclPopUp" onClick={togglePopup}>
                                    <div className="icon_lo">
                                        <img src="assets/images/plan.svg" alt="calendar icon" />
                                    </div>
                                    <div className="lo-dropdown">
                                        <span className="input_lo" style={{fontSize:'14px'}}>FCL, {containerSize}</span>
                                    </div>
                                </div>
                                {showPopup && (
                                    <div className="Shipping_popup">
                                        <div >
                                            <div className="d-flex">
                                                <div>
                                                    <ul className="nav nav-tabs bg_color mb-2">
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link ${activeTab === 'tab1' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('tab1')}
                                                            >
                                                                SEA
                                                            </button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link ${activeTab === 'tab2' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('tab2')}
                                                            >
                                                                LAND
                                                            </button>
                                                        </li>
                                                        <li className="nav-item">
                                                            <button
                                                                className={`nav-link ${activeTab === 'tab3' ? 'active' : ''}`}
                                                                onClick={() => handleTabClick('tab3')}
                                                            >
                                                                AIR
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>

                                       
                                            <div className="tab-content type-ayra">
                                                <div className={`tab-pane py-3 fade ${activeTab === 'tab1' ? 'show active' : ''}`} id="tab1Content">
                                                <select class="container-size-select" value={containerSize} onChange={handleContainerChange}>
                                                {containerOptions.map((status) => (
                                                    <option key={status} value={status}>
                                                    {status}
                                                    </option>
                                                ))}
                                                </select>
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    id='to_location'
                                                    type='text'
                                                    placeholder='Value of Goods'
                                                    value={contractMoney}
                                                    onChange={handleContractMoney}
                                                    autocomplete="off"
                                                />
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked1(!isChecked1)}
                                                    defaultChecked={isChecked1}
                                                /> Trade Finance Options
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked2(!isChecked2)}
                                                    defaultChecked={isChecked2}
                                                /> Trade Quality Options
                                                </div>
                                                <div className={`tab-pane py-3 fade ${activeTab === 'tab2' ? 'show active' : ''}`} id="tab2Content">

                                                <select class="container-size-select" value={containerSize} onChange={handleContainerChange}>
                                                {containerOptions.map((status) => (
                                                    <option key={status} value={status}>
                                                    {status}
                                                    </option>
                                                ))}
                                                </select>

                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    id='to_location'
                                                    type='text'
                                                    placeholder='Value of Goods'
                                                    value={contractMoney}
                                                    onChange={handleContractMoney}
                                                    autocomplete="off"
                                                />

                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked1(!isChecked1)}
                                                    defaultChecked={isChecked1}
                                                /> Trade Finance Options
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked2(!isChecked2)}
                                                    defaultChecked={isChecked2}
                                                /> Trade Quality Options

                                                </div>
                                                <div className={`tab-pane py-3 fade ${activeTab === 'tab3' ? 'show active' : ''}`} id="tab3Content">

                                                <input
                                                    className='input_lo2'
                                                    id='to_location'
                                                    type='text'
                                                    placeholder='Container weight in Kilogram'
                                                    value={containerWeight}
                                                    onChange={handleContainerWeight}
                                                    autocomplete="off"
                                                />

                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    id='to_location'
                                                    type='text'
                                                    placeholder='Value of Goods'
                                                    value={contractMoney}
                                                    onChange={handleContractMoney}
                                                    autocomplete="off"
                                                />
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked1(!isChecked1)}
                                                    defaultChecked={isChecked1}
                                                /> Trade Finance Options
                                                <p></p>
                                                <input
                                                    className='input_lo2'
                                                    type='checkbox'
                                                    onChange={() => setIsChecked2(!isChecked2)}
                                                    defaultChecked={isChecked2}
                                                /> Trade Quality Options

                                                </div>
                                              
                                            </div>
                                        </div>
                                        <button className='btn btn-primary w-100 mt-3' onClick={handleSelectButtonClick}> Select</button>
                                    </div>
                                )}
                                <div className=''>
                                    <div className="icon_search" onClick={handleSearchButtonClick}>
                                        <img src="assets/images/searchIcon.svg" alt="search icon"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const CustomDatePickerInput = ({ value, onClick }) => (
    <button className="btn text_of-date" onClick={onClick}>
        {value ? format(new Date(value), 'dd MMMM yyyy') : 'Select a date'}
    </button>
);
export default Ticket;
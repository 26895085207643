// HeroSlider.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const SingleServices = () => {
    const navigate = useNavigate();
    return (
        <div className="py-5 my-5">
        <div className="container">
            <div className="row">
                <div className="col-md-5 col-lg-5">
                    <div className="serivcs-image">
                        <img src="assets/images/serivcs1.png" alt="Hero" className="img-fluid"
                        />
                    </div>
                </div>
                <div className="col-md-7 col-lg-7">
                    <div className='single-right'>
                        <div className="single-text">
                            <h1>Join the  <span > Revolution</span>  </h1>
                            <p>Ship anywhere, sell everywhere, grow faster. Our end-to-end, blockchain-fueled technology empowers businesses to perform cross-border trade with groundbreaking ease.</p>
                            <button className="btn btn-primary" onClick={() => navigate('/Register')}>Sign Up Now <i className="fas fa-arrow-right"></i></button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>

    );
};

export default SingleServices;

import React, { useState, useEffect } from 'react';
import { writeCookie, readCookie } from '../components/utils';

const YourComponent = () => {
    const finances = ['DNC Finance', 'Axis Bank'];
    const images = ['assets/images/dnc.gif', 'assets/images/axis.png'];
    const interests = ['15%', '18%'];
    let contractMoney = readCookie('contract_money')?readCookie('contract_money'):0;
    contractMoney = parseInt(contractMoney*0.8);
    const credits = [contractMoney, contractMoney, contractMoney];
    const [loandates, setLoanDates] = useState(readCookie('loan_date')?readCookie('loan_date'):'60 days');

    const loandatesoptions = [
      '60 days',
      '90 days',
    ];

    const handleLoanDates = (event, index) => {
      const selectedDate = event.target.value;
      setLoanDates(selectedDate);
    }
    
    const financeDropDown = () => {
        var financeDiv = document.getElementById('financeDiv');
        var icon = document.getElementById('financeDropIcon');
        if(financeDiv.style.display == "none") {
            financeDiv.style.display = "block";
            icon.innerHTML = "arrow_drop_down";
        } else {
            financeDiv.style.display = "none";
            icon.innerHTML = "arrow_drop_up";
        }      
    };

    const handleOptionClick = (index) => {
        var btns = document.getElementsByClassName('select-finance-button');
        var prev = btns[index].innerHTML;
        writeCookie('finance', 'false');
        writeCookie('finance_bank', '');
        for(var i=0; i<btns.length; i++) {
            btns[i].innerHTML = "SELECT";
        }
        if(prev == "SELECT") {
            btns[index].innerHTML = "SELECTED";
            writeCookie('finance_bank', finances[index]);
            writeCookie('finance', 'true');
            writeCookie('loan_date', loandates);
        }
        
        const tradeFinanceElement = document.getElementsByClassName('finances-div');
        if (tradeFinanceElement) {
            const featurebox = tradeFinanceElement[tradeFinanceElement.length-1];
            featurebox.scrollIntoView({ behavior: 'smooth' });
        }
    };
  return (
    <div
      className="bubble-element Group baTaHaMy bubble-r-container flex column"
      style={{
        overflow: 'visible',
        justifyContent: 'flex-start',
        borderRadius: '0px',
        opacity: 1,
        alignSelf: 'center',
        minWidth: '280px',
        maxWidth: '100%',
        order: 7,
        minHeight: '10px',
        height: 'max-content',
        flexGrow: 0,
        flexShrink: 0,
        width: 'calc(100% - 0px)',
        margin: '20px 0px 0px',
        zIndex: 9,
      }}
    >
      <div
        className="bubble-element Group baTaHaNaA bubble-r-container flex column"
        style={{
          overflow: 'visible',
          justifyContent: 'flex-start',
          borderRadius: '0px',
          opacity: 1,
          alignSelf: 'flex-start',
          minWidth: '280px',
          order: 1,
          minHeight: '10px',
          height: 'max-content',
          flexGrow: 0,
          flexShrink: 0,
          width: 'calc(100% - 0px)',
          margin: '0px',
          zIndex: 2,
        }}
      >
        <div
          className="bubble-element Group baTaHaOq bubble-r-container flex row"
          style={{
            overflow: 'visible',
            justifyContent: 'space-between',
            borderRadius: '0px',
            opacity: 1,
            alignSelf: 'flex-start',
            minWidth: '0px',
            order: 1,
            minHeight: '0px',
            height: 'max-content',
            flexGrow: 0,
            flexShrink: 0,
            width: 'calc(100% - 40px)',
            margin: '0px 20px',
            zIndex: 17,
          }}
        >
          <div
            className="bubble-element Text baTaHaNaB"
            id="tradeFinance"
            style={{
              whiteSpace: 'pre-wrap',
              overflow: 'visible',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: 1.4,
              padding: '0px 0px 0px 10px',
              borderRadius: '0px',
              opacity: 1,
              alignSelf: 'center',
              minWidth: '200px',
              maxWidth: '200px',
              order: 1,
              minHeight: '10px',
              width: '200px',
              flexGrow: 1,
              height: 'max-content',
              margin: '0px',
              zIndex: 2,
            }}
          >
            TRADE FINANCE
          </div>
          <button
            className="bubble-element materialicons-Materialicon clickable-element"
            style={{
              alignSelf: 'center',
              minWidth: '45px',
              maxWidth: '45px',
              order: 2,
              minHeight: '45px',
              maxHeight: '45px',
              width: '45px',
              flexGrow: 1,
              height: '45px',
              margin: '0px',
              zIndex: 3,
              padding: '0px',
              textAlign: 'center',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              color: 'rgb(27, 27, 27)',
              borderRadius: '4px',
            }}
          >
            <svg onClick={financeDropDown} viewBox="25 -25 50 50" style={{ width: '100%', height: '100%' }}>
              <text className="material-icons" x="50%" y="50%" id="financeDropIcon" textAnchor="start" style={{ fontSize: '50px', fill: 'currentColor' }}>
                arrow_drop_down
              </text>
            </svg>
          </button>
        </div>
      </div>
      <div
        className="bubble-element Group baTaHaNaD bubble-r-container flex column"
        id="financeDiv"
        style={{
          alignSelf: 'flex-start',
          minWidth: '280px',
          order: 2,
          height: 'max-content',
          flexGrow: 0,
          flexShrink: 0,
          width: 'calc(100% - 0px)',
          margin: '0px',
          zIndex: 3,
          overflow: 'visible',
          justifyContent: 'flex-start',
          borderRadius: '0px',
          opacity: 1,
        }}
      >
        <div
          className="bubble-element RepeatingGroup baTaHaNaE bubble-rg"
          style={{
            overflow: 'auto',
            gap: '0px',
            gridTemplateRows: 'repeat(3, minmax(max-content, auto))',
            gridTemplateColumns: 'repeat(1, minmax(0px, 1fr))',
            gridAutoFlow: 'row',
            borderRadius: '0px',
            opacity: 1,
            alignSelf: 'center',
            minWidth: '280px',
            order: 2,
            minHeight: '0px',
            height: 'max-content',
            flexGrow: 0,
            flexShrink: 0,
            width: 'calc(100% - 0px)',
            margin: '0px',
            zIndex: 2,
          }}
        >
          {finances.map((finance,index) => (
          <div
            className="bubble-element group-item bubble-r-container flex column entry-1 finances-div"
            style={{
              boxSizing: 'content-box',
              justifyContent: 'flex-start',
            }}
          >
            <div
              className="bubble-element Group baTaHaNaM bubble-r-container flex row"
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                boxShadow: 'rgb(170, 170, 170) 2px 2px 8px 0px',
                overflow: 'visible',
                justifyContent: 'space-between',
                gap: '0px',
                borderRadius: '8px',
                padding: '10px',
                opacity: 1,
                alignSelf: 'flex-start',
                minWidth: '0px',
                order: 6,
                minHeight: '0px',
                height: 'max-content',
                flexGrow: 0,
                flexShrink: 0,
                width: 'calc(100% - 60px)',
                margin: '5px 30px',
                zIndex: 12,
              }}
            >
              <div
                className="bubble-element Group baTaHjaO bubble-r-container flex row"
                style={{
                  overflow: 'visible',
                  justifyContent: 'flex-start',
                  gap: '0px',
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '150px',
                  maxWidth: '150px',
                  order: 1,
                  minHeight: '0px',
                  width: '150px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 20,
                }}
              >
                <div
                  className="bubble-element Image baTaHjaR"
                  style={{
                    borderRadius: '0px',
                    opacity: 1,
                    alignSelf: 'flex-start',
                    minWidth: '40px',
                    maxWidth: '100px',
                    order: 1,
                    minHeight: '40px',
                    maxHeight: '40px',
                    width: '100px',
                    flexGrow: 0,
                    flexShrink: 0,
                    height: '40px',
                    margin: '0px',
                    zIndex: 10,
                  }}
                >
                  <img
                    src={images[index]}
                    alt="Bank 1"
                    style={{
                      borderRadius: '8px',
                      opacity: 1,
                      alignSelf: 'flex-start',
                      order: 1,
                      minHeight: '40px',
                      maxHeight: '40px',
                      flexGrow: 0,
                      flexShrink: 0,
                      height: '40px',
                      margin: '0px',
                      zIndex: 15,
                    }}
                  />
                </div>
                <div
                  className="bubble-element Text baTaHjaQ"
                  style={{
                    whiteSpace: 'pre-wrap',
                    overflow: 'visible',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.5,
                    borderRadius: '0px',
                    opacity: 1,
                    alignSelf: 'center',
                    minWidth: '150px',
                    maxWidth: '150px',
                    order: 2,
                    minHeight: '10px',
                    width: '150px',
                    flexGrow: 1,
                    height: 'max-content',
                    margin: '0px',
                    zIndex: 14,
                  }}
                >
                  {finance}
                </div>
              </div>
              <div
                className="bubble-element Text baTaHaNaO"
                style={{
                  whiteSpace: 'pre-wrap',
                  overflow: 'visible',
                  fontSize: '14px',
                  lineHeight: 1.5,
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '140px',
                  maxWidth: '90px',
                  order: 2,
                  minHeight: '10px',
                  width: '90px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 18,
                }}
              >
                <font color="#808080">CREDIT:</font> ${credits[index]}
              </div>

              <div
                className="bubble-element Text baTaHaNaO"
                style={{
                  whiteSpace: 'pre-wrap',
                  overflow: 'visible',
                  fontSize: '14px',
                  lineHeight: 1.5,
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '170px',
                  maxWidth: '90px',
                  order: 2,
                  minHeight: '10px',
                  width: '90px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 18,
                }}
              >
                <select class="container-size-select" value={loandates} onChange={handleLoanDates}>
                  {loandatesoptions.map((status) => (
                      <option key={status} value={status}>
                      {status}
                      </option>
                  ))}
                  </select>
              </div>

              <div
                className="bubble-element Text baTaHaNaP"
                style={{
                  whiteSpace: 'pre-wrap',
                  overflow: 'visible',
                  fontSize: '14px',
                  lineHeight: 1.5,
                  borderRadius: '0px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '150px',
                  maxWidth: '90px',
                  order: 3,
                  minHeight: '10px',
                  width: '90px',
                  flexGrow: 1,
                  height: 'max-content',
                  margin: '0px',
                  zIndex: 19,
                }}
              >
                <font color="#808080">INTEREST:</font> {interests[index]} p.a
              </div>
              <button
                className="clickable-element bubble-element Button baTaHaNl select-button select-finance-button"
               onClick={() => handleOptionClick(index)}
                style={{
                  backgroundColor: '#155de9',
                  borderRadius: '4px',
                  boxShadow: 'rgb(170, 170, 170) 0px 0px 5px 0px',
                  color: 'rgb(255, 255, 255)',
                  fontSize: '14px',
                  letterSpacing: '0px',
                  textAlign: 'center',
                  textTransform: 'none',
                  overflow: 'hidden',
                  padding: '10px',
                  opacity: 1,
                  alignSelf: 'center',
                  minWidth: '100px',
                  maxWidth: '100px',
                  order: 5,
                  minHeight: '40px',
                  height: 'auto',
                  flexGrow: 1,
                  flexShrink: 0,
                  width: '100px',
                  margin: '0px',
                  zIndex: 13,
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                SELECT
              </button>
            </div>
          </div>
          ))}
          {/* Other entries (similar structure) */}
        </div>
      </div>
    </div>
  );
};

export default YourComponent;

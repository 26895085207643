// HeroSlider.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Services = () => {
    return (
        <div className="py-5 services">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <div className="row">
                            <div className="col-lg-12">
                                <h4> Services </h4>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className=" justify-content-center  mt-5">
                            <div className="d-flex justify-content-center">
                                <img style={{width:'48px',height:'48px'}} src='assets/images/s1.png' />
                            </div>
                            <div className="card-body mt-2">
                                <h5 className="card-title2 text-center">Freight Services</h5>
                                <p className="card-text text-center mt-2">
                                Book freight – Sea, Air, Land
                                </p>

                            </div>
                        </div>

                    </div>

                    <div className="col-lg-4 col-md-4">
                        <div className=" justify-content-center  mt-5">
                            <div className="d-flex justify-content-center">
                                <img style={{width:'48px',height:'48px'}} src='assets/images/s2.png' />
                            </div>
                            <div className="card-body mt-2">
                                <h5 className="card-title2 text-center">Trade Finance</h5>
                                <p className="card-text text-center mt-2">
                                Invoice factoring, Revolving Credit line
                                </p>

                            </div>
                        </div>

                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className=" justify-content-center  mt-5">
                            <div className="d-flex justify-content-center">
                                <img style={{width:'48px',height:'48px'}} src='assets/images/s3.png' />
                            </div>
                            <div className="card-body mt-2">
                                <h5 className="card-title2 text-center">Quality Assurance</h5>
                                <p className="card-text text-center mt-2">
                                Vendor due diligence, Factory audit, Loading inspection, Quality testing
                                </p>

                            </div>
                        </div>

                    </div>


                </div>
                <div className="row ">
                <div className="col-lg-4 col-md-4"></div>
                    <div className="col-lg-4 col-md-4">
                        <div className=" justify-content-center  mt-5">
                            <div className="d-flex justify-content-center">
                                <img style={{width:'48px',height:'48px'}} src='assets/images/s4.png' />
                            </div>
                            <div className="card-body mt-2">
                                <h5 className="card-title2 text-center">Payment Services</h5>
                                <p className="card-text text-center mt-2">
                                Instant settlement, LC, Escrow, Forex
                                </p>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    );
};

export default Services;

import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import { Link, useNavigate } from 'react-router-dom';
import { domain, writeCookie } from '../components/utils';
import Swal from 'sweetalert2';

const Register = () => {
    const [showModal, setShowModal] = useState(false);
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        retypePassword: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
        setFormErrors({ ...formErrors, [id]: '' });
    };

    const handleValidation = () => {
        const errors = {};

        if (!formData.firstName.trim()) {
            errors.firstName = 'First name is required';
        }
        if (!formData.lastName.trim()) {
            errors.lastName = 'Last name is required';
        }
        if (!formData.email.trim() || !formData.email.includes('@')) {
            errors.email = 'Please enter a valid email';
        }
        if (!formData.mobile.trim() || isNaN(formData.mobile) || formData.mobile.length !== 10) {
            errors.mobile = 'Please enter a valid 10-digit mobile number';
        }
        if (formData.password.length < 6) {
            errors.password = 'Password should be at least 6 characters long';
        }
        if (formData.password !== formData.retypePassword) {
            errors.retypePassword = 'Passwords do not match';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleClose = () => setShowModal(false);
    const inputsRef = useRef([]);

    const handleChangeotp = (index, e) => {
        const inputValue = e.target.value;
        const regex = /^[0-9\b]+$/;
        if (inputValue === '' || (regex.test(inputValue) && inputValue.length <= 1)) {
            const updatedCode = [...code];
            updatedCode[index] = inputValue;
            setCode(updatedCode);
            if(inputValue === '') {
                if (inputsRef.current[index - 1]) {
                    inputsRef.current[index - 1].focus();
                }
            } else {
                if (inputsRef.current[index + 1]) {
                    inputsRef.current[index + 1].focus();
                }
            }
        }
    };

    const handlePasteOtp = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain');
      
        // Distribute each character to all textboxes
        const updatedCode = [...code];
        for (let i = 0; i < pastedData.length && i < code.length; i++) {
          const char = pastedData[i];
          const regex = /^[0-9\b]+$/;
      
          if (char === '' || (regex.test(char) && char.length <= 1)) {
            
            updatedCode[i] = char;      
            if (inputsRef.current[i + 1]) {
              inputsRef.current[i + 1].focus();
            }
          }
        }
        setCode(updatedCode);
    };

    const handleRegister = () => {
        window.scrollTo(0, 0);
        const isValid = handleValidation();
        if (isValid) {
            setLoading(true);
            fetch(`${domain}apis/users/signup.php`, {
                method: 'POST',
                body: JSON.stringify(formData),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    console.log('OTP sent:', data);
                    if(data.status == 'fail') {
                        Swal.fire({
                            title: 'Error!',
                            text: data.message,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                        });
                    } else {
                        setShowModal(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error registering:', error);
                });
        } else {
            console.log('Form validation failed');
        }
    };
    useEffect(() => {
        if (inputsRef.current[0]) {
            inputsRef.current[0].focus();
        }
    }, []);

    const handleVerify = () => {
        if (code!='') {
            let otp = code.toString().replace(/,/g, "");
            setLoading(true);
            fetch(`${domain}apis/users/verify.php`, {
                method: 'POST',
                body: JSON.stringify({'email':formData.email, 'verify_code': otp}),
            })
                .then((response) => response.json())
                .then((data) => {
                    setLoading(false);
                    console.log('Registration successful:', data);
                    if(data.status == 'fail') {
                        Swal.fire({
                            title: 'Error!',
                            text: data.message,
                            icon: 'error',
                            confirmButtonText: 'Ok',
                        });
                    } else {
                        writeCookie('jwt', data.jwt);
                        writeCookie('email', data.email);
                        writeCookie('id', data.id);
                        writeCookie('name', data.name);
                        writeCookie('pub_key', data.pub_key);
                        writeCookie('role', data.role);
                        navigate('/ContractDetails');
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error('Error registering:', error);
                });
        } else {
            console.log('Please enter 6 digit code');
        }
    };
    useEffect(() => {
        if (inputsRef.current[0]) {
            inputsRef.current[0].focus();
        }
    }, []);


    return (
        <div className={`${loading ? 'loading-background' : 'body_f p-5'}`}>
            {loading && <div className="loading-indicator"><img src = './loading.gif'></img></div>}
            <div className={`${loading ? 'loadingcontainer' : 'container'}`}>
                <div className="row bg-white p-5 border_r_20">
                    <div className="col-md-6 mt-3 Sign_page">
                        {Object.keys(formErrors).length > 0 && (
                            <div className="alert alert-danger mt-3">
                                {Object.values(formErrors).map((error, index) => (
                                    <p key={index}>{error}</p>
                                ))}
                            </div>
                        )}
                        <h1 className="let">Let's Started</h1>
                        <p className="text text-center">Make your booking experience excellent</p>
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <label htmlFor="fname" className="l">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    className="mt-2 w-100 rounded-input mb-4 ll"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="lname" className="l">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    className="mt-2 rounded-input lll mb-4 w-100"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <label htmlFor="email" className="mb-2 l">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="w-100 rounded-input mb-4 mt-2 ll"
                            value={formData.email}
                            onChange={handleChange}
                        /><br />

                        <label htmlFor="mobile" className="mb-2 l">Phone</label>
                        <input
                            type="number"
                            id="mobile"
                            className="w-100 rounded-input mb-4 ll"
                            value={formData.mobile}
                            onChange={handleChange}
                        /><br />

                        <div className="row">
                            <div className="col-md-6">
                                <label htmlFor="pass" className="l">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="mt-2 w-100 rounded-input ll"
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="col-md-6 col-lg-6">
                                <label htmlFor="retypePassword" className="l">Retype Password</label>
                                <input
                                    type="password"
                                    id="retypePassword"
                                    className="mt-2 w-100 rounded-input ll"
                                    value={formData.retypePassword}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="d-grid gap-2 mt-4">
                            <button type="button" className="btn btn-primary reg" onClick={handleRegister}>
                                Register
                            </button>
                        </div>

                        <p className="text-center mt-3">Already have an User? <a href='/SignIn' className="text-primary">Sign In</a></p>
                    </div>

                    <div className="col-md-6 relative">
                        <div className='close_icon'>
                        <Link to="/"><i className="fa-solid fa-xmark"></i> </Link>
                        </div>
                        <img src='assets/images/Mask-group.png' className="w-100" alt="Mask Group" />
                    </div>
                </div>
            </div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', background: 'rgb(0 0 0 / 33%)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h5 className="modal-title" id="staticBackdropLabel"></h5>
                                <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                            </div>
                            <div className="modal-body opt_poup">
                                <h2>OTP Verification</h2>
                                <p>Enter the 6 digit verification code received on<br />
                                    your Email ID</p>
                                <div className="d-flex justify-content-between">
                                    <div className='d-flex code_time'>
                                        <p>Verification code:</p>
                                        <i className="fa-solid fa-circle-exclamation"></i>
                                    </div>
                                    <div className='code_bitn'>
                                        <a className='btn btn-link'>Resend Code</a>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between pb-5">
                                    {Array.from({ length: 6 }, (_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="form-control otp_input"
                                            value={code[index]}
                                            onChange={(e) => handleChangeotp(index, e)}
                                            onPaste={handlePasteOtp}
                                            maxLength="1"
                                            style={{ width: '56px', textAlign: 'center' }}
                                            ref={(input) => (inputsRef.current[index] = input)}
                                        />
                                    ))}
                                </div>
                                <div className=''>
                                    <button className='btn btn-Verify' onClick={handleVerify}>Verify</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Register;

// Shipments.js

import React from 'react';

function Footer() {
    return (
        <div className="back_color">
            <div className="pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-3" style={{display:'flex',justifyContent:'center'}}>                          
                            <div className="Ser_vice">
                            <h4 className="Ser_vices">Services</h4>
                                <ul>
                                    <li><a href="/ContactUs">Ocean Freight</a></li>
                                    <li><a href="/ContactUs">Land Freight</a></li>
                                    <li><a href="/ContactUs">Air Freight</a></li>

                                    <li><a href="/ContactUs">Customs Brokerage</a></li>
                                    <li><a href="/ContactUs">Quality Assurance</a></li>
                                    <li><a href="/ContactUs">Order Management</a></li>
                                    <li><a href="/ContactUs">Credit Line (Trade Finance)</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-3" style={{display:'flex',justifyContent:'center'}}>                          
                            <div className="Ser_vice">
                            <h4 className="Ser_vices">Opportunity</h4>
                                <ul>
                                    <li><a href="/ContactUs">For Freight Providers</a></li>
                                    <li><a href="/ContactUs">For Customs Brokerage Agents</a></li>
                                    <li><a href="/ContactUs">For Trade Finance Providers</a></li>
                                    <li><a href="/ContactUs">API Integrations</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-2" style={{display:'flex',justifyContent:'center'}}>                           
                            <div className="Ser_vice">
                            <h4 className="Ser_vices">Company</h4>
                                <ul>
                                    <li><a href="/AboutUs">About Us</a></li>
                                    <li><a href="/ContactUs">Contact Us</a></li>

                                </ul>
                            </div>
                        </div>                   

                    </div>
                       <div className="bor_der">
                        
                       </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <p className="copyright"><span className="px-4">Copyright © 2024 Taram.Inc</span>|<span className="px-4">Privacy policy</span>|<span className="px-4">Terms of service</span></p>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    );
}

export default Footer;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import { Link, useNavigate } from 'react-router-dom';
import { domain, writeCookie } from '../components/utils';
import Swal from 'sweetalert2';
const SignIn = () => {
    const [formData, setFormData] = useState({
        'email': '',
        'password': ''
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };
    const handleLogin = () => {
        if(formData.email.trim() === '' || formData.email.trim() === '') {
            Swal.fire({
                title: 'Error!',
                text: 'Email or Password field can not be blank',
                icon: 'error',
                confirmButtonText: 'Ok',
            });
            return;
        }
        setLoading(true);
        fetch(`${domain}apis/users/login.php`, {
            method: 'POST',
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Login successful:', data);
                setLoading(false);
                if(data.status == 'fail') {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Wrong Credential!',
                        icon: 'error',
                        confirmButtonText: 'Ok',
                    });
                } else {
                    writeCookie('jwt', data.jwt);
                    writeCookie('email', data.email);
                    writeCookie('id', data.id);
                    writeCookie('name', data.name);
                    writeCookie('pub_key', data.pub_key);
                    writeCookie('role', data.role);
                    navigate('/ContractDetails');
                }
            })
            .catch((error) => {
                console.error('Error registering:', error);
                setLoading(false);
        });
    };

    const handleKeyUp = (event) => {
        if(event.keyCode === 13) {
            handleLogin();
        }
    };

    return (
        <div className={`${loading ? 'loading-background' : 'body_f p-5'}`}>
            {loading && <div className="loading-indicator"><img src = './loading.gif'></img></div>}
            <div className={`${loading ? 'loadingcontainer' : 'container'}`}>
                <div className="row bg-white p-5 border_r_20">
                    <div className="col-md-6 mt-3 Sign_page">
                        <h1 className="let">
                            Sign In
                        </h1>
                        <p className="text-center">Make your booking experience excellent</p>

                        <label htmlFor="email" className="mb-2 mt-5">Email</label><br />
                        <input value={formData.email} onChange={handleChange} onKeyUp={handleKeyUp} type="email" id="email" className="rounded-input mb-4 mt-2 w-100" /><br />

                        <label htmlFor="number" className="mb-2">Password</label><br />
                        <input value={formData.password} onChange={handleChange} onKeyUp={handleKeyUp} type="password" id="password" className="rounded-input mb-3  w-100" /><br />

                        <p className="text-end for">Forgot Password</p>

                        <div className="d-grid gap-2 mt-5">
                            <button type="button" className="btn btn-primary reg" onClick={handleLogin}>Sign In</button>
                        </div>
                        <div className='text-center py-3'>
                            <a href='/Register' className=" mt-3 link_r">Don’t have an account yet? <span className="text-primary">Sign Up</span></a>
                        </div>
                    </div>

                    <div className="col-md-6 relative">
                        <div className='close_icon'>
                        <Link to="/"><i className="fa-solid fa-xmark"></i> </Link>
                        </div>
                        <img src='assets/images/Mask-group.png' className="w-100" alt="Mask Group" />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default SignIn;

import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import Hader from '../components/Header'
import Footer from '../components/Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import Pusher from 'pusher-js';
import { domain, getLoginInfo, readCookie } from '../components/utils';
const ChatPage = () => {
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    const [allChats, setAllChats] = useState([]);
    const [infos, setInfos] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [email, setEmail] = useState(false);
    const [jwt, setJwt] = useState('');
    const [id, setId] = useState(false);
    const [cid, setCid] = useState(0);
    const [curContractName, setCurContractName] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    useEffect(() => {
        const curcid = queryParams.get('cid');
        const userInfo = getLoginInfo();
        if (!userInfo) {
            navigate('/SignIn');
        } else {
            setEmail(userInfo.email);
            setJwt(userInfo.jwt);
            setId(parseInt(userInfo.id));
            setCid(curcid);
        }
        const pusher = new Pusher('37dd9dd5198a7d36ae0d', {
            cluster: 'ap2',
            encrypted: true,
        });
        const channel = pusher.subscribe('group-chat-'+curcid);

        const handleMessage = (data) => {
            setMessages((prevMessages) => [...prevMessages, data.message]);
            setInfos((prevInfos) => [...prevInfos, JSON.parse(data.info)]);
        };
    
        // Bind the event
        channel.bind('message', handleMessage);
        fetch(`${domain}apis/getChat.php`, {
            method: 'POST',
            body: JSON.stringify({cid: curcid }),
        })
        .then((response) => response.json())
        .then((data) => {
            setCurContractName(data[0].contract_name);
            setMessages(data.map(message => message.message));
            setInfos(data.map(item => ({
                from_id: parseInt(item.from_id),
                from_name: item.from_name,
                from_email: item.from_email,
                datetime: item.datetime,
              })));
        })
        .catch((error) => {
            console.error('Error Creating Contract:', error);
        });
        const headers = {
            'Authorization': `Bearer ${userInfo.jwt}`,
        };
        fetch(`${domain}apis/getAllChat.php`, {
            method: 'POST',
            headers
        })
        .then((response) => response.json())
        .then((data) => {
            setAllChats(data);
            if(!queryParams.get('cid') && data[0]) {
                selectContract(data[0].cid);
            }
        })
        .catch((error) => {
            console.error('Error Creating Contract:', error);
        });

        return () => {
            channel.unbind('message', handleMessage);
            pusher.unsubscribe('group-chat-'+curcid);
        };
    }, [cid]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        // Scroll to the bottom of the chat container
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    };

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleKeyUp = (event) => {
        if(event.keyCode === 13) {
            handleSendMessage();
        }
    };

    const selectContract = (cid) => {
        navigate('/Chat?cid='+cid);
        setCid(cid);
        fetch(`${domain}apis/getChat.php`, {
            method: 'POST',
            body: JSON.stringify({cid: cid }),
        })
        .then((response) => response.json())
        .then((data) => {
            setCurContractName(data[0].contract_name);
            setMessages(data.map(message => message.message));
            setInfos(data.map(item => ({
                from_id: parseInt(item.from_id),
                from_name: item.from_name,
                from_email: item.from_email,
                datetime: item.datetime,
              })));
        })
        .catch((error) => {
            console.error('Error Creating Contract:', error);
        });
    };

    const handleSendMessage = () => {
        // Send the message to the backend
        const headers = {
            'Authorization': `Bearer ${jwt}`,
        };
        fetch(`${domain}apis/chat.php`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ message: newMessage, cid: cid }),
        });  
        setNewMessage('');
    };
    return (
        <>
            <Hader />
            <div className='chat_body_bg'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className='char_user_list p-3'>
                                <div className='sracch_box'>
                                    <div className="input-group">
                                        <span className=" icon_cearch_ct"><i className="fas fa-search"></i></span>
                                        <input type="text" className="form-control input-group_input_ct" placeholder="Search..." />
                                    </div>
                                </div>
                                {allChats.map((chat, index) => (
                                <div className={`friend-info d-flex justify-content-between ${chat.cid === cid && 'selected'}`} onClick={() => selectContract(chat.cid)}>
                                    <div className="d-flex">
                                        <img src="./apis/contract.png" alt="User Profile" className="rounded-circle me-3" width="70" />
                                        <div>
                                            <h5>{chat.contract_name}</h5>
                                            <p>{chat.from_name}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p><span>{chat.datetime}</span></p>
                                    </div>
                                </div>
                                ))}
                            </div>
                            <div>
                            </div>
                        </div>


                        <div className="col-md-8 border-end py-8 chat_ayra" ref={chatContainerRef}>
                            <div className='header_cht'>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <img src="./apis/contract.png" alt="User Profile" className="rounded-circle me-3" width="70" />
                                        <div>
                                            <h4>{curContractName}</h4>
                                        </div>
                                    </div>
                                    <div className="ml-auto right_icon">
                                        <a href='#' className='srach_icon_ty'>
                                            <i className="fas fa-search"></i> Search
                                        </a>
                                        <a href='#' className='search_icon_12'>
                                            <i className="fa-solid fa-phone"></i>
                                        </a>
                                        <a href='#' className='search_icon_12'>
                                            <i className="fa-solid fa-ellipsis-vertical"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-body">
                            {messages.map((msg, index) => (
                                <div className={infos[index].from_id === id ? "sent-message" : "received-message"}>
                                    {infos[index].from_id === id ? (
                                    <div className="message-timestamp">
                                    <p>{infos[index].datetime}</p>
                                    </div>
                                    ) : (
                                    <div className="message-avatar">
                                    <img src="./apis/user.png" alt="User Avatar" className="rounded-circle" width="50" height="50" />
                                    </div>
                                    )}
                                    <div className="message-content">
                                    {infos[index].from_id != id &&
                                    <div>
                                        <span className="chat-sender-name">{infos[index].from_name}</span>
                                        <span className="chat-sender-email">{infos[index].from_email}</span>
                                    </div>    
                                    }
                                        <p>{msg}</p>
                                    </div>
                                    {infos[index].from_id != id ? (
                                    <div className="message-timestamp">
                                    <p>{infos[index].datetime}</p>
                                    </div>
                                    ) : (
                                    <div className="message-avatar">
                                    <img src="./apis/myself.png" alt="User Avatar" className="rounded-circle" width="50" height="50" />
                                    </div>
                                    )}
                                </div>
                            ))}
                            </div>
                            <div className="chat-footer mt-4">
                                <div className="input-group_search_cht d-flex">
                                    <input type="text" className="form-control icon_div_input" placeholder="Send message here..." value={newMessage} onChange={handleInputChange} onKeyUp={handleKeyUp} />
                                    <div className='icon_div'>
                                        <a href='#'> <i className="fa-solid fa-paperclip"></i></a>
                                        <a href='#'><i className="fa-solid fa-microphone"></i></a>
                                        <a href='#'> <i className="fa-regular fa-face-grin-beam"></i></a>
                                    </div>
                                    <div className="input-group-append send_btns" onClick={handleSendMessage}>
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ChatPage;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Deals = () => {
    return (
        <div className="import_export">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="d-flex justify-content-center my-4">
                            <h1>
                                Hot<span> Deals</span>
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="row p-2">
                            <div className="col-lg-2 col-8" style={{width:50+'%',textAlign:'center'}}>
                                <h4> Credit Line  <img style={{marginTop:'-7px',width:'25px'}} src='assets/images/s2.png' /> </h4>                              
                            </div>
                            <div className="col-lg-2 col-8" style={{width:50+'%',textAlign:'center'}}>
                                <h4> Quality Assurance  <img style={{marginTop:'-7px',width:'25px'}} src='assets/images/s3.png' /> </h4>                              
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/dnc.webp" style={{width:'241px',height:'171px'}} className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/dnc.gif' style={{width:'35px',height:'24px'}} />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">DNC Chits Pvt. Ltd.</h5>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div>
                                                    <h6>10% p.a. loan</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 30,000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/axis.avif" style={{width:'241px',height:'171px'}} className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/axislogo.png' style={{width:'35px',height:'24px'}} />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">Axis Bank</h5>
                                    </div>
                                </div>
                                <div className="row py-2">

                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div>
                                                    <h6>12% p.a. loan</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 50,000</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/sgs.jpg" style={{width:'241px',height:'171px'}} className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/sgslogo.png' style={{height:'24px'}} />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">SGS</h5>
                                    </div>
                                </div>
                                <div className="row py-2">

                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div>
                                                    <h6 style={{fontSize:'11px'}}>Factory Inspection</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 300</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/xsbuilding.jpg" style={{width:'241px',height:'171px'}} className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/xs.jpg' style={{height:'24px'}} />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">Excel Surveyors</h5>
                                    </div>
                                </div>
                                <div className="row py-2">

                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div>
                                                <h6 style={{fontSize:'11px'}}>Loading Inspection</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 400</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row mt-5">
                    <div className="col-lg-12">
                        <div className="row p-2">
                        <div className="col-lg-2 col-8" style={{width:50+'%',textAlign:'center'}}>
                            <h4> Import to USA  <img style={{marginTop:'-7px',width:'30px'}} src='assets/images/flag_usa.png' /> </h4>                              
                        </div>
                        <div className="col-lg-2 col-8" style={{width:50+'%',textAlign:'center'}}>
                            <h4> Export from USA  <img style={{marginTop:'-7px',width:'30px'}} src='assets/images/flag_usa.png' /> </h4>                              
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/c1.jpg" className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/flag_usa.png' />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">Los Angeles, US</h5>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div className="col-3">
                                                    <img src='assets/images/Deals2.png' />
                                                </div>
                                                <div className="col-9">
                                                    <h6>FCL.20’ ST</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 1802</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/c2.jpg" className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/flag_usa.png' />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">New York,US</h5>
                                    </div>
                                </div>
                                <div className="row py-2">

                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div className="col-lg-3">
                                                    <img src='assets/images/Deals2.png' />
                                                </div>
                                                <div className="col-lg-9">
                                                    <h6>FCL.20’ ST</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 2443</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/c5.jpg" className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/flag_india.png' />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">Mumbai,IN</h5>
                                    </div>
                                </div>
                                <div className="row py-2">
                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div className="col-3">
                                                    <img src='assets/images/Deals2.png' />
                                                </div>
                                                <div className="col-9">
                                                    <h6>FCL.20’ ST</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 2024</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                        <div className="ca_rd" >
                            <img src="assets/images/c6.jpg" className="card-img-top" />
                            <div className="card-body">
                                <div className="row py-2">
                                    <div className="col-2">
                                        <img src='assets/images/flag_france.png' />
                                    </div>
                                    <div className="col-2">
                                        <h5 className="card-title">Paris,FR</h5>
                                    </div>
                                </div>
                                <div className="row py-2">

                                    <div className="col-6">
                                        <div className="car_dbg">
                                            <div className="row p-2">
                                                <div className="col-lg-3">
                                                    <img src='assets/images/Deals2.png' />
                                                </div>
                                                <div className="col-lg-9">
                                                    <h6>FCL.20’ ST</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="d-flex justify-content-end">
                                            <h5 className="card_ti">USD 2025</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default Deals;
